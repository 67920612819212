import Link from '@mui/material/Link';
import { NavLink } from "react-router-dom";
import Social from "./social";
import WonderlandIcon from "../../../assets/icons/small-logo-box.png";
import DashboardIcon from "../../../assets/icons/dashboard.svg";
import DocsIcon from "../../../assets/icons/stake.svg";
import WrapIcon from "../../../assets/icons/vault.svg";
import FarmIcon from "../../../assets/icons/farm.svg";
import FundIcon from "../../../assets/icons/fund.png";
import ZapIcon from "../../../assets/icons/arrows.svg";
import DelegateIcon from "../../../assets/icons/governance.svg";
import ElysiumIcon from "../../../assets/icons/bridge.svg"

import "./drawer-content.scss";

function NavContent() {
    return (
        <div className="dapp-sidebar">
            <div className="branding-header">
                <Link href="https://empyreal.app" target="_blank">
                    <img alt="" src={WonderlandIcon} />
                </Link>

                {/* {address && (
                    <div className="wallet-link">
                        <Link href={`https://cchain.explorer.avax.network/address/${address}`} target="_blank">
                            <p>{shorten(address)}</p>
                        </Link>
                    </div>
                )} */}
            </div>


            <div className="dapp-menu-links">
                <div className="dapp-nav">
                    {/* <Link
                        component={NavLink}
                        to="/horizon"
                        className={"button-dapp-menu"}
                    >
                        <div className="dapp-menu-item">
                            <img alt="" src={StakeIcon} />
                            <p>Stake (Horizon)</p>
                        </div>
                    </Link>



                    <Link
                        component={NavLink}
                        to="/lbp"
                        className={"button-dapp-menu"}
                    >
                        <div className="dapp-menu-item">
                            <img alt="" src={FarmIcon} />
                            <p>LBP</p>
                        </div>
                    </Link> */}

                    <Link
                        component={NavLink}
                        to="/dashboard"
                        className={"button-dapp-menu"}
                    >
                        <div className="dapp-menu-item">
                            <img alt="" src={DashboardIcon} />
                            <p>Dashboard</p>
                        </div>
                    </Link>

                    {/* <Link
                        component={NavLink}
                        to="/wrap"
                        className={"button-dapp-menu"}
                    >
                        <div className="dapp-menu-item">
                            <img alt="" src={WrapIcon} />
                            <p>Wrap ARB</p>
                        </div>
                    </Link> */}

                    {/* <Link
                        component={NavLink}
                        to="/farm"
                        className={"button-dapp-menu"}
                    >
                        <div className="dapp-menu-item">
                            <img alt="" src={FarmIcon} />
                            <p>Old Farms</p>
                        </div>
                    </Link> */}

                    <Link
                        component={NavLink}
                        to="/singlestaking"
                        className={"button-dapp-menu"}
                    >
                        <div className="dapp-menu-item">
                            <img alt="" src={FarmIcon} />
                            <p>Farms</p>
                        </div>
                    </Link>

                    {/* <Link
                        component={NavLink}
                        to="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x772598E9e62155D7fDFe65FdF01EB5a53a8465BE"
                        className={"button-dapp-menu"}
                    >
                        <div className="dapp-menu-item">
                            <img alt="" src={ZapIcon} />
                            <p>Swap</p>
                        </div>
                    </Link> */}


                    {/* <Link
                        component={NavLink}
                        to="/horizon"
                        className={"button-dapp-menu"}
                    >
                        <div className="dapp-menu-item">
                            <img alt="" src={FundIcon} />
                            <p>Horizon</p>
                        </div>
                    </Link>

                    <Link
                        component={NavLink}
                        to="/zap"
                        className={"button-dapp-menu"}
                    >
                        <div className="dapp-menu-item">
                            <img alt="" src={ZapIcon} />
                            <p>Zap</p>
                        </div>
                    </Link> */}

                    <Link
                        component={NavLink}
                        to="/delegate"
                        className={"button-dapp-menu"}
                    >
                        <div className="dapp-menu-item">
                            <img alt="" src={DelegateIcon} />
                            <p>Delegate</p>
                        </div>
                    </Link>

                    <Link
                        component={NavLink}
                        to="/leverage/2xeth"
                        className={"button-dapp-menu"}
                    >
                        <div className="dapp-menu-item">
                            <img alt="" src={ElysiumIcon} />
                            <p>Leverage</p>
                        </div>
                    </Link>

                </div>
            </div>
            {/* <div className="dapp-menu-doc-link">
                <Link href="https://docs.empyreal.app" target="_blank">
                    <img alt="" src={DocsIcon} />
                    <p>Docs</p>
                </Link>
            </div> */}
            <Social />
        </div >
    );
}

export default NavContent;
