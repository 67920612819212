import Drawer from '@mui/material/Drawer';

import DrawerContent from "./drawer-content";
import { DRAWER_WIDTH } from "constants/style";
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

const StyledDrawer = styled(Drawer)`
    width: ${DRAWER_WIDTH};
    borderRight: 0;
`;

const MobileDrawer = styled(Drawer)`
    borderRight: 0;
`;

// const useStyles = makeStyles(theme => ({
//     drawer: {
//         [theme.breakpoints.up("md")]: {
//             width: DRAWER_WIDTH,
//             flexShrink: 0,
//         },
//     },
//     drawerPaper: {
//         width: DRAWER_WIDTH,
//         borderRight: 0,
//     },
// }));

const styles = (theme) => ({
    root: {
        [theme.breakpoints.up('md')]: {
            width: DRAWER_WIDTH,
            flexShrink: 0,
        },
    },
});


function NavDrawer({ mobileOpen, handleDrawerToggle }) {
    const theme = useTheme();
    return (
        <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            onClick={handleDrawerToggle}
            ModalProps={{
                keepMounted: true,
            }}
            styles={{
                borderRight: 0,
                width: DRAWER_WIDTH,
                [theme.breakpoints.up('md')]: {
                    width: DRAWER_WIDTH,
                    flexShrink: 0,
                },
            }}
        >
            <DrawerContent />
        </Drawer>
    );
}

export default NavDrawer;
