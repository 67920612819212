import { ethers } from "ethers";
import { useState, useEffect } from "react";

import { useEmpyrealPrice, useFirmamentPrice } from "./tokenPrices";
import { IERC20, IHorizon } from "utils/abis";
import { useContractRead, useBalance } from "wagmi";
import { EMPYREAL_ADDRESS, EMPY_PAIR_ADDRESS, FIRMAMENT_ADDRESS, HORIZON_ADDRESS } from "constants";
import { useEmpyrealEgArbLPPrice, useFirmUSDCLPPrice } from "./tokenPrices";
import { FIRM_PAIR_ADDRESS, OLD_HORIZON_ADDRESS } from "constants";
import { NEW_FARM_ADDRESS as FARM_ADDRESS, FARM_ADDRESS as OLD_FARM_ADDRESS } from "constants";
import { formatEther } from "utils/etherUtils";


export function useHorizonTVL() {
    const [horizonTVL, setHorizonTVL] = useState();
    const firmPrice = useFirmamentPrice();
    const { data: horizonBalance } = useBalance({
        address: HORIZON_ADDRESS,
        token: FIRMAMENT_ADDRESS,
        watch: true,
        cacheTime: 10_000,
    });

    useEffect(() => {
        setHorizonTVL(firmPrice * formatEther(horizonBalance?.value));
    }, [firmPrice, horizonBalance])

    return horizonTVL
}

export function useHorizonAPR() {
    const [apr, setAPR] = useState(0);
    const horizonTVL = useHorizonTVL();
    const marketCap = useEmpyrealMarketCap()
    const { data: epoch } = useContractRead({
        address: HORIZON_ADDRESS,
        chainId: 42161,
        abi: IHorizon,
        functionName: 'epoch',
        args: [],
    });

    useEffect(() => {
        const percent = (225 - epoch?.sub(15).mul(4)) / 10000;
        setAPR((marketCap * percent * 3 * 365) / horizonTVL);
    }, [marketCap, horizonTVL, epoch])

    return apr;
}

export function useEmpyrealMarketCap() {
    const { empyrealPriceInUSD } = useEmpyrealPrice();
    const { data: totalSupply } = useContractRead({
        address: EMPYREAL_ADDRESS,
        chainId: 42161,
        abi: IERC20,
        functionName: 'totalSupply',
        args: [],
        watch: true,
        cacheTime: 10_000,
    });

    return empyrealPriceInUSD * totalSupply / 1e18 || "0";
}

export function usePool0TVL() {
    const [tvl, setTVL] = useState(0);
    const lpPrice = useEmpyrealEgArbLPPrice();
    const { data: balance } = useContractRead({
        address: EMPY_PAIR_ADDRESS,
        chainId: 42161,
        abi: IERC20,
        functionName: 'balanceOf',
        args: [FARM_ADDRESS],
        watch: true,
        cacheTime: 10_000,
    });
    useEffect(() => {
        setTVL(lpPrice * formatEther(balance));
    }, [lpPrice, balance])

    return tvl;
}

export function usePool1TVL() {
    const [tvl, setTVL] = useState(0);
    const lpPrice = useFirmUSDCLPPrice();
    const { data: balance } = useContractRead({
        address: FIRM_PAIR_ADDRESS,
        chainId: 42161,
        abi: IERC20,
        functionName: 'balanceOf',
        args: [FARM_ADDRESS],
        watch: true,
        cacheTime: 10_000,
    });
    useEffect(() => {
        setTVL(lpPrice * ethers.utils.formatEther(balance || "0"));
    }, [lpPrice, balance])

    return tvl;
}


export function useTVL() {
    const [tvl, setTVL] = useState(0);
    const pool0TVL = usePool0TVL();
    const pool1TVL = usePool1TVL();
    const horizonTVL = useHorizonTVL()
    useEffect(() => {
        setTVL(pool0TVL + pool1TVL + horizonTVL);
    }, [pool0TVL, pool1TVL, horizonTVL])

    return tvl;
}


export function useFirmMarketCap() {
    const [marketCap, setMarketCap] = useState(0);
    const firmPrice = useFirmamentPrice();
    const { data: farmBalance } = useContractRead({
        address: FIRMAMENT_ADDRESS,
        chainId: 42161,
        abi: IERC20,
        functionName: 'balanceOf',
        args: [FARM_ADDRESS],
        cacheTime: 10_000,
    });
    const oldHorizonBalance = 283.98
    const { data: oldFarmBalance } = useContractRead({
        address: FIRMAMENT_ADDRESS,
        chainId: 42161,
        abi: IERC20,
        functionName: 'balanceOf',
        args: [OLD_FARM_ADDRESS],
        watch: true,
        cacheTime: 10_000,
    });
    const { data: totalSupply } = useContractRead({
        address: FIRMAMENT_ADDRESS,
        chainId: 42161,
        abi: IERC20,
        functionName: 'totalSupply',
        args: [],
        watch: true,
        cacheTime: 10_000,
    });

    useEffect(() => {
        setMarketCap((formatEther(totalSupply) - formatEther(farmBalance) - formatEther(oldFarmBalance) - oldHorizonBalance) * firmPrice)
    }, [totalSupply, farmBalance, oldFarmBalance, firmPrice, oldHorizonBalance]);

    return marketCap;
}
