import React, { useCallback, useState, useEffect } from "react";
import { InjectedConnector } from 'wagmi/connectors/injected';
import TextField from '@mui/material/TextField';
import Zoom from '@mui/material/Zoom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { InputAdornment } from '@mui/material';
import { useAccount, useConnect } from "wagmi";
import Button from "../../components/Button";
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import "./stake.scss";
// import { erc20ABI } from 'wagmi';
import { useBalance } from 'wagmi'
import { ethers } from "ethers";
import { useNetwork } from 'wagmi'
import IERC20 from "./IERC20.json";
import PresaleABI from "./TokenSale.json"
import { getProvider } from '@wagmi/core'

import { useContractRead, usePrepareContractRead, usePrepareContractWrite, useContractWrite } from 'wagmi'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'green',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ARBITRUM_ADDRESS = "0x912ce59144191c1204e64559fe8253a0e49e6548";
const PRESALE_ADDRESS = "0xF74B7f66FB0B9A893E2D5c7b2FfBf7e728529a27";

function Presale() {
    const { address, isConnected } = useAccount();
    const { connect } = useConnect({
        connector: new InjectedConnector(),
    });
    const [selectedButton, setSelected] = useState("EMPYREAL");
    const [quantity, setQuantity] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [tokenAmount, setTokenAmount] = useState("");
    const network = useNetwork();
    const provider = getProvider();
    console.log(provider);

    const { data } = useBalance({
        address,
        token: ARBITRUM_ADDRESS,
    });

    // const Arbitrum = new ethers.Contract(ARBITRUM_ADDRESS, IERC20ABI);

    const { data: tokenAllowance } = useContractRead({
        address: ARBITRUM_ADDRESS,
        chainId: 42161,
        abi: IERC20,
        functionName: 'allowance',
        args: [address, PRESALE_ADDRESS],
        enabled: address,
        watch: true,
    });

    const { data: totalSold } = useContractRead({
        address: PRESALE_ADDRESS,
        chainId: 42161,
        abi: PresaleABI,
        functionName: 'tokensSold',
        args: [],
        enabled: address,
        watch: true,
    });

    const { data: pendingBalance } = useContractRead({
        address: PRESALE_ADDRESS,
        chainId: 42161,
        abi: PresaleABI,
        functionName: 'balanceOf',
        args: [address],
        enabled: address,
        watch: true,
    });

    const { config: approveConfig } = usePrepareContractWrite({
        address: ARBITRUM_ADDRESS,
        chainId: 42161,
        abi: IERC20,
        functionName: "approve",
        args: [PRESALE_ADDRESS, ethers.constants.MaxUint256],
    });
    const { write: doApprove } = useContractWrite(approveConfig);

    const { config: buyConfig } = usePrepareContractWrite({
        address: PRESALE_ADDRESS,
        abi: PresaleABI,
        functionName: "buyTokens",
        args: [tokenAmount],
    });
    const { write: doBuy, ...etc } = useContractWrite(buyConfig);

    const isApproved = tokenAllowance && tokenAllowance.gt(0);
    console.log(isApproved);

    const { config: claimConfig } = usePrepareContractWrite({
        address: PRESALE_ADDRESS,
        abi: PresaleABI,
        functionName: "claim",
        args: [],
    });
    const { write: doClaim } = useContractWrite(claimConfig);

    return (
        <div className="stake-view">
            <Zoom in={true}>
                <div className="farm-card">
                    <Grid className="farm-card-grid" container direction="column" spacing={2}>
                        <Grid item>
                            <Grid item xs={12}>
                                <h1 style={{ textAlign: 'left', color: 'white' }}>
                                    PRESALE (closed)
                                </h1>
                            </Grid>
                            {/* <div className="farm-card-header">
                                <p className="farm-card-header-title">Presale</p>
                            </div> */}
                        </Grid>
                    </Grid>

                    {/* <hr />
                    <div className="farm-card-action-help-text">
                        <p style={{ textAlign: 'left' }}>
                            Tokens will be claimable on Mar 27
                        </p>
                    </div>
                    <hr /> */}

                    <Grid item>
                        <div className="farm-card-metrics">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">Amount for Sale</p>
                                        <p className="farm-card-metrics-value">33,333</p>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">Amount Sold</p>
                                        <p className="farm-card-metrics-value">{totalSold ? ethers.utils.formatEther(totalSold) : 0}</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">Price</p>
                                        <p className="farm-card-metrics-value">1 ARB</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    {/* <Modal
                        open={showModal}
                        onClose={() => setShowModal(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Text in a modal
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                            </Typography>
                        </Box>
                    </Modal> */}

                    <div className="farm-card-area">
                        {!address && (
                            <div className="farm-card-wallet-notification">
                                <div className="farm-card-wallet-connect-btn" onClick={() => connect()}>
                                    <p>Connect Wallet</p>
                                </div>
                            </div>
                        )}
                        {address && (
                            <div>
                                <div className="farm-card-action-area">
                                    <div className="farm-card-action-row">
                                        <TextField
                                            variant="outlined"

                                            placeholder="Amount"
                                            className="farm-card-action-input"
                                            value={tokenAmount}
                                            onChange={e => setTokenAmount(e.target.value.replace(/[^\d]/g, ""))}
                                            // labelWidth={0}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {/* <div onClick={setMax} className="farm-card-action-input-btn">
                                                        <p>Max</p>
                                                    </div> */}
                                                </InputAdornment>
                                            }
                                        />
                                    </div>
                                    <div className="farm-card-action-row">
                                        <Box sx={{ display: 'flex-right', color: 'white', textAlign: 'right', marginRight: '25px', width: '100%', justifyContent: 'space-evenly' }}>
                                            <p style={{ cursor: 'pointer' }} onClick={
                                                () => setTokenAmount(parseInt(data?.formatted).toString())
                                            }>Balance: {data?.formatted} {data?.symbol}</p>
                                        </Box>
                                    </div>

                                    <div className="farm-card-action-row">
                                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
                                            <Box sx={{
                                                p: 1,
                                                m: 1,
                                            }}>
                                                {
                                                    isApproved ?
                                                        <Button isDisabled={true} action={() => doBuy?.()} text={"PURCHASE"}></Button> :
                                                        <Button isDisabled={true} action={() => doApprove?.()} text={"APPROVE"}></Button>

                                                }
                                            </Box>
                                        </Box>
                                    </div>

                                    <hr />
                                    <div className="farm-card-action-help-text">
                                        <p style={{ textAlign: 'left' }}>
                                            Tokens will be claimable on Mar 25
                                        </p>
                                    </div>
                                    <hr />

                                    <div className="farm-card-metrics">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <div className="farm-card-tvl">
                                                    <p className="farm-card-metrics-title">Claimable Balance</p>
                                                    <p className="farm-card-metrics-value">{pendingBalance ? ethers.utils.formatEther(pendingBalance) : 0} EMP</p>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>


                                    <div className="farm-card-action-row">
                                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
                                            <Box sx={{
                                                p: 1,
                                                m: 1,
                                            }}>
                                                <Button action={() => doClaim()} text={"CLAIM"}></Button>
                                            </Box>
                                        </Box>
                                    </div>
                                </div>

                            </div>
                        )}
                    </div>
                </div>
            </Zoom >
        </div >
    );
}

export default Presale;
