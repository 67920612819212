import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useContractRead } from "wagmi";

import {
    CAMELOT_ROUTER_ADDRESS,
    FIRMAMENT_ADDRESS,
    ARBITRUM_ADDRESS,
    USDC_ADDRESS,
    EMPYREAL_ADDRESS,
    egARB_ADDRESS,
    EMPY_PAIR_ADDRESS,
    FIRM_PAIR_ADDRESS,
    NEW_EMPYREAL_ADDRESS,
    WETH_ADDRESS,
} from "constants";
import { formatEther } from "utils/etherUtils";
import { IERC20, IRouter } from "utils/abis";

export function useFirmamentPrice() {
    const { data: firmPrice } = useContractRead({
        address: CAMELOT_ROUTER_ADDRESS,
        chainId: 42161,
        abi: IRouter,
        functionName: 'getAmountsOut',
        args: [ethers.utils.parseEther("1"), [FIRMAMENT_ADDRESS, USDC_ADDRESS]],
        watch: true,
        cacheTime: 10_000,
    });

    return firmPrice && firmPrice[1].toNumber() / 1000000;
}

export function useArbitrumPrice() {
    const { data: arbitrumPrice } = useContractRead({
        address: CAMELOT_ROUTER_ADDRESS,
        chainId: 42161,
        abi: IRouter,
        functionName: 'getAmountsOut',
        args: [ethers.utils.parseEther("1"), [ARBITRUM_ADDRESS, USDC_ADDRESS]],
        watch: true,
        cacheTime: 10_000,
    });

    return arbitrumPrice && arbitrumPrice[1].toNumber() / 1000000;
}

export function useEthPrice() {
    const { data: ethPrice } = useContractRead({
        address: CAMELOT_ROUTER_ADDRESS,
        chainId: 42161,
        abi: IRouter,
        functionName: 'getAmountsOut',
        args: [ethers.utils.parseEther("1"), [WETH_ADDRESS, USDC_ADDRESS]],
        watch: true,
        cacheTime: 10_000,
    });

    return ethPrice && ethPrice[1].toNumber() / 1000000;
}

export function useEmpyrealPrice() {
    const [empyrealPriceInUSD, setEmpPrice] = useState(0);
    const { data: amountsOut } = useContractRead({
        address: CAMELOT_ROUTER_ADDRESS,
        chainId: 42161,
        abi: IRouter,
        functionName: 'getAmountsOut',
        args: [ethers.utils.parseEther("1"), [EMPYREAL_ADDRESS, egARB_ADDRESS]],
        watch: true,
        cacheTime: 10_000,
    });
    const empyrealPriceInArb = formatEther(amountsOut?.[1])
    const arbPrice = useArbitrumPrice();
    useEffect(() => {
        setEmpPrice(empyrealPriceInArb * arbPrice);
    }, [empyrealPriceInArb, arbPrice]);

    return { empyrealPriceInArb, empyrealPriceInUSD };
}

export function useEmpyrealEgArbLPPrice() {
    const [lpPrice, setLpPrice] = useState(0);
    const { data: pairBalance } = useContractRead({
        address: EMPYREAL_ADDRESS,
        chainId: 42161,
        abi: IERC20,
        functionName: 'balanceOf',
        args: [EMPY_PAIR_ADDRESS],
        cacheTime: 10_000,
    });
    const { empyrealPriceInUSD } = useEmpyrealPrice();
    const { data: lpTotalSupply } = useContractRead({
        address: EMPY_PAIR_ADDRESS,
        chainId: 42161,
        abi: IERC20,
        functionName: 'totalSupply',
        args: [],
        watch: false,
        cacheTime: 10_000,
    });

    useEffect(() => {
        if (!!pairBalance && !!empyrealPriceInUSD && !!lpTotalSupply) {
            setLpPrice(formatEther(pairBalance) * empyrealPriceInUSD * 2 / formatEther(lpTotalSupply));
        }
    }, [empyrealPriceInUSD, pairBalance, lpTotalSupply]);

    return lpPrice;
}

export function useFirmUSDCLPPrice() {
    const [lpPrice, setLpPrice] = useState(0);
    const { data: pairBalance } = useContractRead({
        address: FIRMAMENT_ADDRESS,
        chainId: 42161,
        abi: IERC20,
        functionName: 'balanceOf',
        args: [FIRM_PAIR_ADDRESS],
    });
    const firmamentPrice = useFirmamentPrice();

    const { data: lpTotalSupply } = useContractRead({
        address: FIRM_PAIR_ADDRESS,
        chainId: 42161,
        abi: IERC20,
        functionName: 'totalSupply',
        args: [],
        watch: false,
    });

    useEffect(() => {
        if (!!pairBalance && !!firmamentPrice && !!lpTotalSupply) {
            setLpPrice(formatEther(pairBalance) * firmamentPrice * 2 / formatEther(lpTotalSupply || "0"));
        }
    }, [firmamentPrice, pairBalance, lpTotalSupply]);

    return lpPrice;
}

export function useNewEmpyrealPrice() {
    const [empyrealPriceInUSD, setEmpPrice] = useState(0);
    const { data: amountsOut } = useContractRead({
        address: CAMELOT_ROUTER_ADDRESS,
        chainId: 42161,
        abi: IRouter,
        functionName: 'getAmountsOut',
        args: [ethers.utils.parseEther("1"), [NEW_EMPYREAL_ADDRESS, egARB_ADDRESS]],
        watch: true,
        cacheTime: 10_000,
    });
    const empyrealPriceInArb = formatEther(amountsOut?.[1])
    const arbPrice = useArbitrumPrice();
    useEffect(() => {
        setEmpPrice(empyrealPriceInArb * arbPrice);
    }, [empyrealPriceInArb, arbPrice]);

    return { empyrealPriceInArb, empyrealPriceInUSD };
}
