import React, { useCallback, useState, useEffect } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useBalance } from 'wagmi'
import OutlinedInput from '@mui/material/OutlinedInput';
import Zoom from '@mui/material/Zoom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { InputAdornment } from '@mui/material';
import { useAccount, useConnect } from "wagmi";

import Button from "../../components/Button";
import "./farm.scss";
import IERC20ABI from "./IERC20.json";
import { useContractRead, usePrepareContractWrite, useContractWrite } from 'wagmi'
import { ethers } from "ethers";
import IFarmABI from "./IFarm.json";
import IStagingABI from "./IStaging.json";
import { NEW_FARM_ADDRESS as FARM_ADDRESS } from "constants";

import { formatEther } from "utils/etherUtils";
import { useEmpyrealEgArbLPPrice, useFirmUSDCLPPrice } from "utils/tokenPrices";
import { useRewardRate } from "utils/farmAPR";
import { parseEther } from "utils/etherUtils";
import { CAMELOT_ROUTER_ADDRESS } from "constants";
import { FIRMAMENT_ADDRESS } from "constants";
import LBP from "views/LBP";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const PAIR_ADDRESS = "0x06675843400F2267060ee886C9088fF498f7c8eC";
const FIRM_PAIR_ADDRESS = "0x87E65159EdAfAe4bb1CCD0c94c7Ec9427409B370";
const STAGING = "0xD780259Cd78af74728521125F95C7E1d6938c7e7";

function Farm() {
    const { address } = useAccount();
    const { connect } = useConnect();
    const [quantity, setQuantity] = useState("");
    const [pid, setPid] = useState(0);

    const pairAddress = pid === 0 ? PAIR_ADDRESS : FIRM_PAIR_ADDRESS;

    const { data: LPBalance } = useBalance({
        address,
        token: pairAddress,
        watch: true,
    });
    console.log('LPBalance', LPBalance);
    const { data: tokenAllowance } = useContractRead({
        address: pairAddress,
        chainId: 42161,
        abi: IERC20ABI,
        functionName: 'allowance',
        args: [address, FARM_ADDRESS],
        enabled: address,
        watch: true,
    });
    const { config: approveConfig } = usePrepareContractWrite({
        address: pairAddress,
        chainId: 42161,
        abi: IERC20ABI,
        functionName: "approve",
        args: [FARM_ADDRESS, ethers.constants.MaxUint256],
    });
    const { data: maxAllowanceCamelot } = useContractRead({
        address: FIRMAMENT_ADDRESS,
        chainId: 42161,
        abi: IERC20ABI,
        functionName: 'allowance',
        args: [address, CAMELOT_ROUTER_ADDRESS],
        enabled: address,
        watch: true,
    });

    const approvedCamelot = maxAllowanceCamelot?.gt(parseEther("100000"))
    const { config: camelotApproveConfig } = usePrepareContractWrite({
        address: FIRMAMENT_ADDRESS,
        chainId: 42161,
        abi: IERC20ABI,
        functionName: "approve",
        args: [CAMELOT_ROUTER_ADDRESS, ethers.constants.MaxUint256],
    });
    const { write: doApproveCamelot } = useContractWrite(camelotApproveConfig);
    const { write: doApprove } = useContractWrite(approveConfig);

    const hasApproved = tokenAllowance && tokenAllowance.gt(parseEther(quantity));


    const { config: stakeConfig } = usePrepareContractWrite({
        address: FARM_ADDRESS,
        chainId: 42161,
        abi: IFarmABI,
        functionName: "deposit",
        args: [pid, parseEther(quantity)?.gte(LPBalance?.value || "0") ? LPBalance?.value : parseEther(quantity)],
        enabled: !!(parseFloat(quantity) && LPBalance?.value) && !(quantity === "") && quantity > 0,
    });
    const { write: doStake } = useContractWrite(stakeConfig);

    const { data: userInfo } = useContractRead({
        address: FARM_ADDRESS,
        chainId: 42161,
        abi: IFarmABI,
        functionName: 'userInfo',
        args: [pid, address],
        enabled: address,
        watch: true,
    });

    const { data: pendingShare } = useContractRead({
        address: FARM_ADDRESS,
        chainId: 42161,
        abi: IFarmABI,
        functionName: 'pendingShare',
        args: [pid, address],
        enabled: address,
        watch: true,
    });

    const { data: stagingUserInfo } = useContractRead({
        address: STAGING,
        chainId: 42161,
        abi: IStagingABI,
        functionName: 'userInfo',
        args: [pid, address],
        enabled: address,
        watch: true,
    });

    const { config: claimConfig } = usePrepareContractWrite({
        address: FARM_ADDRESS,
        chainId: 42161,
        abi: IFarmABI,
        functionName: "deposit",
        args: [pid, 0],
    });
    const { write: doClaim } = useContractWrite(claimConfig);

    const parsedQuantity = parseFloat(quantity);
    const { config: unstakeConfig } = usePrepareContractWrite({
        address: FARM_ADDRESS,
        chainId: 42161,
        abi: IFarmABI,
        functionName: "withdraw",
        args: [pid, userInfo?.amount],
        enabled: !!userInfo?.amount,
    });
    const { write: doWithdraw } = useContractWrite(unstakeConfig);

    const { config: unstakePartialConfig } = usePrepareContractWrite({
        address: FARM_ADDRESS,
        chainId: 42161,
        abi: IFarmABI,
        functionName: "withdraw",
        args: [pid, quantity && parseEther(quantity)],
        enabled: !(quantity === "") && parseEther(quantity).lte(userInfo?.amount || "0") && !!userInfo?.amount,
    });
    const { write: doWithdrawPartial } = useContractWrite(unstakePartialConfig);

    const { config: claimStagedConfig } = usePrepareContractWrite({
        address: STAGING,
        chainId: 42161,
        abi: IStagingABI,
        functionName: "claim",
        args: [pid, stagingUserInfo?.amount],
        enabled: stagingUserInfo?.amount.gt(0) && (stagingUserInfo?.withdrawTime?.add(24 * 3600 * 3)?.toString()) < (Date.now() / 1000),
    });
    const { write: doClaimStaged } = useContractWrite(claimStagedConfig);

    const lp0Price = useEmpyrealEgArbLPPrice();
    const lp1Price = useFirmUSDCLPPrice();
    const tokenPrice = pid === 0 ? lp0Price : lp1Price;

    const rewardAPR = useRewardRate(pid);

    const [value, setValue] = useState(0);
    const unlockTime = stagingUserInfo?.withdrawTime?.eq(0) ? "-" : new Date(stagingUserInfo?.withdrawTime * 1000 + 3600 * 24 * 3 * 1000);

    const handleChange = (event, newValue) => {
        if (pid === 0) {
            setPid(1);
        } else {
            setPid(0);
        }
        setValue(newValue);
    };

    return (
        <div className="farm-view">
            <Zoom in={true}>
                <div className="farm-card">
                    <Grid className="farm-card-grid" container direction="column" spacing={2}>
                        <Grid item>
                            <div className="farm-card-header">
                                <p className="farm-card-header-title">Farms</p>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <div className="farm-card-metrics">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                sx={{
                                    '& .MuiTabs-indicator': { backgroundColor: 'white !important' },
                                    '& .MuiTab-root': { color: 'gray' },
                                    '& .Mui-selected': { color: 'white !important' },
                                }}
                            >
                                <Tab label="EMPYREAL/egARB" {...a11yProps(0)} />
                                <Tab label="FIRMAMENT/USDC" {...a11yProps(1)} />
                            </Tabs>
                        </div>
                    </Grid>

                    <Grid item>
                        <div className="farm-card-metrics">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">Total APR</p>
                                        <p className="farm-card-metrics-value">{(rewardAPR * 100).toFixed(2) || "?"}%</p>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">Amount Staked</p>
                                        <p className="farm-card-metrics-value">{formatEther(userInfo?.amount)}</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">Pending Reward</p>
                                        <p className="farm-card-metrics-value">{parseFloat(formatEther(pendingShare ?? "0")).toFixed(8)} FIRM</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">Staked Value</p>
                                        <p className="farm-card-metrics-value">{`$${parseFloat((tokenPrice * formatEther(userInfo?.amount))).toFixed(2)}`}</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <div className="farm-card-area">
                        {!address && (
                            <div className="farm-card-wallet-notification">
                                <div className="farm-card-wallet-connect-btn" onClick={connect}>
                                    <p>Connect Wallet</p>
                                </div>
                                <p className="farm-card-wallet-desc-text">Connect your wallet</p>
                            </div>
                        )}
                        {address && (
                            <div>
                                <div className="farm-card-action-area">
                                    <div className="farm-card-action-row">
                                        <OutlinedInput
                                            type="number"
                                            placeholder="Amount"
                                            className="farm-card-action-input"
                                            value={quantity}
                                            onChange={e => setQuantity(e.target.value)}
                                            // labelWidth={0}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {/* <div onClick={setMax} className="farm-card-action-input-btn">
                                                        <p>Max</p>
                                                    </div> */}
                                                </InputAdornment>
                                            }
                                        />
                                    </div>
                                    <div className="farm-card-action-row">
                                        <Box sx={{ display: 'flex-right', color: 'white', textAlign: 'right', marginRight: '25px', width: '100%', justifyContent: 'space-evenly' }}>
                                            <p onClick={() => setQuantity(LPBalance?.formatted)}>Balance: {LPBalance?.formatted || "0"}</p>
                                        </Box>
                                    </div>

                                    <div className="farm-card-action-row">
                                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
                                            {!approvedCamelot && pid === 1 ? <>
                                                <Button action={() => doApproveCamelot()} text={"Approve Camelot"}></Button>
                                            </> : hasApproved ? <>
                                                <Box sx={{
                                                    p: 1,
                                                    m: 1,
                                                }}>
                                                    <Button isDisabled={quantity === "" || parseFloat(quantity) === 0 || LPBalance?.value.eq(0)} action={() => doStake?.()} text={"STAKE"}></Button>
                                                </Box>
                                                <Box sx={{
                                                    p: 1,
                                                    m: 1,
                                                }}>
                                                    <Button isDisabled={!doClaim} action={() => doClaim?.()} text={"CLAIM"}></Button>
                                                </Box>

                                                <Box sx={{
                                                    p: 1,
                                                    m: 1,
                                                }}>
                                                    <Button isDisabled={!doWithdrawPartial} sx={{ p: 1, m: 1 }} text="Unstake" action={() => doWithdrawPartial()} />
                                                    <p style={{ color: 'white', marginTop: '5px', marginLeft: '-20px' }}>Input Amount to Withdraw</p>
                                                </Box>
                                            </> :
                                                <>
                                                    <Button isDisabled={!doApprove} action={() => doApprove()} text={"Approve"}></Button>
                                                    <Button isDisabled={!doWithdrawPartial} sx={{ p: 1, m: 1 }} text="Unstake" action={() => doWithdrawPartial()} />
                                                </>
                                            }

                                        </Box>
                                    </div>



                                    <div className="farm-card-action-help-text">
                                        <h2 style={{ marginLeft: '10px', color: 'white', textAlign: 'left' }}>Notes:</h2>
                                        <p style={{ textAlign: 'left' }}>
                                            FIRM allowance must be set to max allowance.
                                        </p>
                                        <p style={{ textAlign: 'left' }}>
                                            When funds are staged, THEY YIELD NO REWARDS.  Funds must remain in a cool off period of 3 days, where no revenue is earned.
                                        </p>
                                        <br />
                                        <p style={{ textAlign: 'left' }}>
                                            When Empyreal is under peg... Some of your Empyreal or Firmament is burned when unstaking.  You will get back your LP and the unmatched burnt ARB
                                        </p>
                                    </div>
                                </div>

                                <div className="farm-card-action-area">
                                    <div className="farm-card-metrics">
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <div className="farm-card-tvl">
                                                    <p className="farm-card-metrics-title">Staged Funds</p>
                                                    <p className="farm-card-metrics-value">{ethers.utils.formatEther(stagingUserInfo?.amount || "0")}</p>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div className="farm-card-tvl">
                                                    <p className="farm-card-metrics-title">Time to Unlock</p>
                                                    <p className="farm-card-metrics-value">
                                                        {unlockTime.toLocaleString()}
                                                    </p>
                                                </div>
                                            </Grid>
                                        </Grid>

                                    </div>

                                    <div className="farm-card-action-row">

                                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
                                            <Box sx={{
                                                p: 2,
                                                m: 2,
                                            }}>
                                                <Button action={() => doClaimStaged()} isDisabled={stagingUserInfo?.withdrawTime.gt(ethers.BigNumber.from(parseInt(Date.now() / 1000 - 259200)))} text={"REMOVE STAGED FUNDS"}></Button>
                                            </Box>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Zoom >
        </div>
    );
}

export default Farm;
