import { ethers } from "ethers";

export function formatEther(value) {
    try {
        return ethers.utils.formatEther(value);
    } catch (e) {
        return "0";
    }
}

export function parseEther(value) {
    try {
        return ethers.utils.parseEther(value);
    } catch (e) {
        return ethers.BigNumber.from(0);
    }
}
