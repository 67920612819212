import React, { useCallback, useState } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import OutlinedInput from '@mui/material/OutlinedInput';
import Zoom from '@mui/material/Zoom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { InputAdornment } from '@mui/material';
import { useAccount, useConnect } from "wagmi";
import Button from "../../components/Button";
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useContractRead, usePrepareContractRead, usePrepareContractWrite, useContractWrite } from 'wagmi'
import egArbABI from "./egARB.json";
import { ethers } from "ethers";
import { parseEther } from "utils/etherUtils";

import "./stake.scss";

import IERC20 from "./IERC20.json";
import { useBalance } from 'wagmi'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'green',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const ARBITRUM_ADDRESS = "0x912ce59144191c1204e64559fe8253a0e49e6548";
const egARB_ADDRESS = "0x53e131a94Df92787a080F61FE67Ce1EA6640EA4C";

function Stake() {
    const { address } = useAccount();
    const { connect } = useConnect();
    const [selectedButton, setSelected] = useState("EMPYREAL");
    const [quantity, setQuantity] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [isWrap, setIsWrap] = useState(true);
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setIsWrap(newValue === 0);
        setValue(newValue);
    };

    const { data: arbBalance } = useBalance({
        address,
        token: ARBITRUM_ADDRESS,
        watch: true,
    });

    const { data: egArbBalance } = useBalance({
        address,
        token: egARB_ADDRESS,
        watch: true,
    });

    const { data: egArbAllowance } = useContractRead({
        address: egARB_ADDRESS,
        chainId: 42161,
        abi: IERC20,
        functionName: 'allowance',
        args: [address, egARB_ADDRESS],
        enabled: address,
        watch: true,
    });
    const { data: arbAllowance } = useContractRead({
        address: ARBITRUM_ADDRESS,
        chainId: 42161,
        abi: IERC20,
        functionName: 'allowance',
        args: [address, egARB_ADDRESS],
        enabled: address,
        watch: true,
    });

    const { config: approveConfig } = usePrepareContractWrite({
        address: ARBITRUM_ADDRESS,
        chainId: 42161,
        abi: IERC20,
        functionName: "approve",
        args: [egARB_ADDRESS, ethers.constants.MaxUint256],
    });
    const { write: doApproveArb } = useContractWrite(approveConfig);
    const { config: approveEgConfig } = usePrepareContractWrite({
        address: egARB_ADDRESS,
        chainId: 42161,
        abi: IERC20,
        functionName: "approve",
        args: [egARB_ADDRESS, ethers.constants.MaxUint256],
    });
    const { write: doApproveEgARB } = useContractWrite(approveEgConfig);
    const doApprove = isWrap ? doApproveArb : doApproveEgARB

    const { config: wrapConfig } = usePrepareContractWrite({
        address: egARB_ADDRESS,
        chainId: 42161,
        abi: egArbABI,
        functionName: "deposit",
        args: [parseEther(quantity)],
        enabled: isWrap,
    });
    const { write: doWrap } = useContractWrite(wrapConfig);
    const { config: unwrapConfig } = usePrepareContractWrite({
        address: egARB_ADDRESS,
        chainId: 42161,
        abi: egArbABI,
        functionName: "withdraw",
        args: [parseEther(quantity)],
        enabled: !isWrap,
    });
    const { write: doUnwrap } = useContractWrite(unwrapConfig);

    const hasApproved = isWrap ? arbAllowance && arbAllowance.gte(parseEther(quantity)) : egArbAllowance && egArbAllowance.gte(parseEther(quantity));

    const tokenBalance = isWrap ? arbBalance : egArbBalance;
    const SYMBOL = isWrap ? "ARB" : "egARB";
    const actionText = isWrap ? "Wrap" : "Unwrap";
    const doAction = isWrap ? doWrap : doUnwrap;

    return (
        <div className="stake-view">
            <Zoom in={true}>
                <div className="farm-card">
                    <Grid className="farm-card-grid" container direction="column" spacing={2}>
                        <Grid item>
                            <div className="farm-card-header">
                                <p className="farm-card-header-title">egARB</p>
                            </div>
                        </Grid>
                    </Grid>

                    <div className="farm-card">
                        <Grid item>
                            <div className="farm-card-metrics">
                                <Grid container spacing={2}>
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        sx={{
                                            '& .MuiTabs-indicator': { backgroundColor: 'white !important' },
                                            '& .MuiTab-root': { color: 'gray' },
                                            '& .Mui-selected': { color: 'white !important' },
                                        }}
                                    >
                                        <Tab label="WRAP" {...a11yProps(0)} />
                                        <Tab label="UNWRAP" {...a11yProps(1)} />
                                    </Tabs>
                                </Grid>
                            </div>
                        </Grid>
                    </div>

                    <div className="farm-card-area">
                        {!address && (
                            <div className="farm-card-wallet-notification">
                                <div className="farm-card-wallet-connect-btn" onClick={connect}>
                                    <p>Connect Wallet</p>
                                </div>
                                <p className="farm-card-wallet-desc-text">Connect your wallet to wrap</p>
                            </div>
                        )}
                        {address && (
                            <div>
                                <div className="farm-card-action-area">
                                    <div className="farm-card-action-row">
                                        <OutlinedInput
                                            type="number"
                                            placeholder="Amount"
                                            className="farm-card-action-input"
                                            value={quantity}
                                            onChange={e => setQuantity(e.target.value)}
                                            // labelWidth={0}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {/* <div onClick={setMax} className="farm-card-action-input-btn">
                                                        <p>Max</p>
                                                    </div> */}
                                                </InputAdornment>
                                            }
                                        />
                                    </div>
                                    <div className="farm-card-action-row">
                                        <Box sx={{ display: 'flex-right', color: 'white', textAlign: 'right', marginRight: '25px', width: '100%', justifyContent: 'space-evenly' }}>
                                            <p style={{ cursor: 'pointer' }} onClick={() => setQuantity(tokenBalance?.formatted)}>
                                                Balance: {tokenBalance?.formatted} {SYMBOL}</p>
                                        </Box>
                                    </div>

                                    <div className="farm-card-action-row">
                                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
                                            <Box sx={{
                                                p: 1,
                                                m: 1,
                                            }}>
                                                {hasApproved ? <Button action={() => doAction()} text={actionText}></Button> : <Button action={() => doApprove()} text={"APPROVE"}></Button>}
                                            </Box>
                                        </Box>
                                    </div>

                                </div>

                            </div>
                        )}
                    </div>
                </div>
            </Zoom >
        </div >
    );
}

export default Stake;
