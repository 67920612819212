import Link from '@mui/material/Link';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as GitHub } from "../../../assets/icons/github.svg";
import { ReactComponent as Twitter } from "../../../assets/icons/twitter.svg";
import { ReactComponent as Telegram } from "../../../assets/icons/telegram.svg";
import { ReactComponent as Discord } from "../../../assets/icons/discord.svg";

export default function Social() {
    return (
        <div className="social-row">
            <Link href="https://github.com/empyrealapp" target="_blank">
                <SvgIcon color="primary" component={GitHub} />
            </Link>

            <Link href="https://t.me/empyrealportal" target="_blank">
                <SvgIcon style={{ marginLeft: '-3px', marginTop: '-2px', height: '17px', overflow: 'visible' }} color="primary" component={Telegram} />
            </Link>

            <Link href="https://discord.gg/pVD6ZPtVym" target="_blank">
                <SvgIcon color="primary" component={Discord} />
            </Link>

            <Link href="https://twitter.com/empyrealarb" target="_blank">
                <SvgIcon color="primary" component={Twitter} />
            </Link>
        </div>
    );
}
