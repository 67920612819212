import { useState, useEffect } from "react";
import styled from "styled-components";

import { Row } from "./element";
import ModalContainer from "./modal";
import { coins } from "./constants";

const CoinButton = ({ selectedCoin, coinOptions, setCurrency }) => {
  const [visible, setVisible] = useState(false);
  const [isCoinSelect, setCoinSelect] = useState();
  const [coinImage, setCoinImage] = useState();

  function toggleOpen() {
    setVisible(!visible);
  }

  useEffect(() => {
    const c = coins.filter(coin => coin.symbol === selectedCoin)[0];
    setCoinImage(c?.icon);
    setCoinSelect(c?.symbol);
  }, [selectedCoin]);

  return (
    <Wrapper onClick={() => toggleOpen()}>
      <img
        src={
          coinImage
        }
        alt="usdt"
      />
      {isCoinSelect ? isCoinSelect : "ETH"}
      <ModalContainer
        setVisible={setVisible}
        visible={visible}
        setCoinImage={setCoinImage}
        setCoinSelect={setCoinSelect}
        setCurrency={setCurrency}
        coinOptions={coinOptions}
      />
    </Wrapper>
  );
};

const Wrapper = styled(Row)`
  box-shadow: inset -2px -2px 0px 0.25px #9fa9b3;
  padding: 8px;
  font-weight: bold;
  justify-content: space-around;
  border: 0.5px solid #484d56;
  color: white;
  img {
    width: 20px;
  }
  width: 200px;
  &:active {
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.6);
  }
`;
export default CoinButton;
