import React, { useCallback, useState } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import OutlinedInput from '@mui/material/OutlinedInput';
import Zoom from '@mui/material/Zoom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { InputAdornment } from '@mui/material';
import { useAccount, useConnect } from "wagmi";
import Button from "../../components/Button";
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useContractRead, usePrepareContractRead, usePrepareContractWrite, useContractWrite } from 'wagmi'
import { ethers } from "ethers";
import { parseEther, formatEther } from "utils/etherUtils";
import { ILeverageToken } from "utils/abis";
import { useBalance } from 'wagmi'

import "./stake.scss";

const DOUBLE_ETH = "0x262CB4f81b09779e9dAC5bA74576BB5f6fCF7872";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'green',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const ARBITRUM_ADDRESS = "0x912ce59144191c1204e64559fe8253a0e49e6548";
const egARB_ADDRESS = "0x53e131a94Df92787a080F61FE67Ce1EA6640EA4C";

function Stake() {
    const { address } = useAccount();
    const { connect } = useConnect();
    const [selectedButton, setSelected] = useState("EMPYREAL");
    const [quantity, setQuantity] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [isWrap, setIsWrap] = useState(true);
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setIsWrap(newValue === 0);
        setValue(newValue);
    };

    const { data: ethBalance } = useBalance({
        address,
        watch: true,
    });

    const { data: doubleEthBalance } = useBalance({
        address,
        token: DOUBLE_ETH,
        watch: true,
    });

    const { data: dailyFee } = useContractRead({
        address: DOUBLE_ETH,
        chainId: 42161,
        abi: ILeverageToken,
        functionName: 'dailyFee',
        args: [],
        watch: true,
    });
    const { data: price } = useContractRead({
        address: DOUBLE_ETH,
        chainId: 42161,
        abi: ILeverageToken,
        functionName: 'price',
        args: [],
        watch: true,
    });
    const { data: ethValue } = useContractRead({
        address: DOUBLE_ETH,
        chainId: 42161,
        abi: ILeverageToken,
        functionName: 'collateralValue',
        args: [],
        watch: true,
    });
    const { data: usdValue } = useContractRead({
        address: DOUBLE_ETH,
        chainId: 42161,
        abi: ILeverageToken,
        functionName: 'debtValue',
        args: [],
        watch: true,
    });

    const { data: ratio } = useContractRead({
        address: DOUBLE_ETH,
        chainId: 42161,
        abi: ILeverageToken,
        functionName: 'getCurrentRatio',
        args: [],
        watch: true,
    });
    const { data: redeemAmount } = useContractRead({
        address: DOUBLE_ETH,
        chainId: 42161,
        abi: ILeverageToken,
        functionName: 'getRedeemAmount',
        args: [doubleEthBalance?.value],
        watch: true,
    });
    const { data: redeemQuantity } = useContractRead({
        address: DOUBLE_ETH,
        chainId: 42161,
        abi: ILeverageToken,
        functionName: 'getRedeemAmount',
        args: [parseEther(quantity)],
        watch: true,
    });

    const { config: purchaseEthConfig } = usePrepareContractWrite({
        address: DOUBLE_ETH,
        chainId: 42161,
        abi: ILeverageToken,
        functionName: "purchaseETH",
        args: [],
        overrides: {
            value: parseEther(quantity),
        },
        enabled: !!Number(quantity)
    });
    const { write: doPurchaseEth } = useContractWrite(purchaseEthConfig);
    const { config: redeemEthConfig } = usePrepareContractWrite({
        address: DOUBLE_ETH,
        chainId: 42161,
        abi: ILeverageToken,
        functionName: "redeemETH",
        args: [parseEther(quantity)],
        enabled: !!Number(quantity),
    });
    const { write: doRedeemEth } = useContractWrite(redeemEthConfig);

    const { config: handleUpdateConfig } = usePrepareContractWrite({
        address: DOUBLE_ETH,
        chainId: 42161,
        abi: ILeverageToken,
        functionName: "handleUpdate",
        args: [],
        enabled: address?.toLowerCase() === "0x3d261844a8a744633936f47766e6a741212130bf".toLowerCase(),
    });
    const { write: doHandleUpdate } = useContractWrite(handleUpdateConfig);

    const { config: rebaseConfig } = usePrepareContractWrite({
        address: DOUBLE_ETH,
        chainId: 42161,
        abi: ILeverageToken,
        functionName: "rebase",
        args: [],
    });
    const { write: doRebase } = useContractWrite(rebaseConfig);

    const doAction = isWrap ? doPurchaseEth : doRedeemEth;
    const actionText = isWrap ? "Purchase" : "Redeem";
    const SYMBOL = isWrap ? "ETH" : "2xETH";
    const tokenBalance = isWrap ? ethBalance : doubleEthBalance;

    return (
        <div className="stake-view">
            <Zoom in={true}>
                <div className="farm-card">
                    <Grid className="farm-card-grid" container direction="column" spacing={2}>
                        <Grid item>
                            <div className="farm-card-header">
                                <p className="farm-card-header-title">2xETH</p>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <div className="farm-card-metrics">
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={6}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">Price</p>
                                        <p className="farm-card-metrics-value">
                                            {Number(formatEther(price))?.toFixed(4)} 2xETH = 1 ETH
                                        </p>
                                    </div>
                                </Grid>

                                <Grid item xs={6} sm={6}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">Leverage Ratio</p>
                                        <p className="farm-card-metrics-value">{(ratio?.div(1e4)?.toString() / 10000)?.toFixed(4)}</p>
                                    </div>
                                </Grid>
                                {/* 
                                <Grid item xs={4} sm={4}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">Value in ETH</p>
                                        <p className="farm-card-metrics-value">{Number(formatEther(redeemAmount))?.toFixed(4)}</p>
                                    </div>
                                </Grid> */}
                                <Grid item xs={4} sm={4}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">Daily Fee</p>
                                        {/* <p className="farm-card-metrics-value">{Number(dailyFee) / 1000}%</p> */}
                                        <p className="farm-card-metrics-value">0.01%</p>
                                    </div>
                                </Grid>

                                <Grid item xs={4} sm={4}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">Total ETH</p>
                                        <p className="farm-card-metrics-value">${(Number(ethValue?.toString() || 0) / 1e8)?.toFixed(4)}</p>
                                    </div>
                                </Grid>

                                <Grid item xs={4} sm={4}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">USD borrowed</p>
                                        <p className="farm-card-metrics-value">${(Number(usdValue?.toString() || 0) / 1e8)?.toFixed(4)}</p>
                                    </div>
                                </Grid>

                            </Grid>
                        </div>
                    </Grid>

                    <div className="farm-card">
                        <Grid item>
                            <div className="farm-card-metrics">
                                <Grid container spacing={2}>
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        sx={{
                                            '& .MuiTabs-indicator': { backgroundColor: 'white !important' },
                                            '& .MuiTab-root': { color: 'gray' },
                                            '& .Mui-selected': { color: 'white !important' },
                                        }}
                                    >
                                        <Tab label="PURCHASE" {...a11yProps(0)} />
                                        <Tab label="REDEEM" {...a11yProps(1)} />
                                    </Tabs>
                                </Grid>
                            </div>
                        </Grid>
                    </div>

                    <div className="farm-card-area">
                        {!address && (
                            <div className="farm-card-wallet-notification">
                                <div className="farm-card-wallet-connect-btn" onClick={connect}>
                                    <p>Connect Wallet</p>
                                </div>
                                <p className="farm-card-wallet-desc-text">Connect your wallet to wrap</p>
                            </div>
                        )}
                        {address && (
                            <div>
                                <div className="farm-card-action-area">
                                    <div className="farm-card-action-row">
                                        <OutlinedInput
                                            type="number"
                                            placeholder="Amount"
                                            className="farm-card-action-input"
                                            value={quantity}
                                            onChange={e => setQuantity(e.target.value)}
                                            // labelWidth={0}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {/* <div onClick={setMax} className="farm-card-action-input-btn">
                                                        <p>Max</p>
                                                    </div> */}
                                                </InputAdornment>
                                            }
                                        />
                                    </div>
                                    <div className="farm-card-action-row">
                                        <Box sx={{ display: 'flex-right', color: 'white', textAlign: 'right', marginRight: '25px', width: '100%', justifyContent: 'space-evenly' }}>
                                            <p style={{ cursor: 'pointer' }} onClick={() => setQuantity(tokenBalance?.formatted)}>
                                                Balance: {tokenBalance?.formatted} {SYMBOL}
                                            </p>
                                            {!isWrap && <p>
                                                Amount Out: {Number(formatEther(redeemQuantity))?.toFixed(4)}
                                            </p>}
                                        </Box>
                                    </div>

                                    <div className="farm-card-action-row">
                                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
                                            <Box sx={{
                                                p: 1,
                                                m: 1,
                                            }}>
                                                <Button action={() => doAction()} text={actionText} />
                                            </Box>
                                        </Box>
                                    </div>
                                    {doRebase && <Button action={() => doRebase()} text={"REBASE"} />}
                                    {doHandleUpdate && <Button action={() => doHandleUpdate()} text={"UPDATE"} />}

                                </div>

                            </div>
                        )}
                    </div>
                </div>
            </Zoom >
        </div >
    );
}

export default Stake;
