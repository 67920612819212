import { Button, Modal } from "@nextui-org/react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { CgArrowsExchangeV } from "react-icons/cg";
import { useAccount, useConnect, useBalance } from "wagmi";
import { useContractRead, usePrepareContractWrite, useContractWrite } from 'wagmi'
import { useNewEmpyrealPrice, useArbitrumPrice, useEthPrice } from "utils/tokenPrices";

import ZapperAbi from "./ICamelotZap.json";
import { IERC20 } from "utils/abis";
import { ethers } from "ethers";
import { parseEther, formatEther } from "utils/etherUtils";
import { Col, Row } from "./element";
import CoinButton from "./coinbutton";
import "./swap.scss";
import { coins } from "./constants";

const ZAPPER_ADDRESS = "0x631693B529f180328CAEFd0c8DB17d84F3804275";

const MAPPING = {
  'EMP': new Set(['ARB', 'ETH']),
  'ARB': new Set(['EMP']),
  'ETH': new Set('EMP'),
};

const QuickSwap = () => {
  const { address } = useAccount();
  const { connect } = useConnect();

  const [topAmount, setTopAmount] = useState("");
  const [bottomAmount, setBottomAmount] = useState("");
  const [topCurrency, setTopCurrency] = useState(coins[0]);
  const [bottomCurrency, setBottomCurrency] = useState(coins[1]);
  const [slippage, setSlippage] = useState(0.1);
  const [slippageInput, setSlippageInput] = useState("");

  useEffect(() => {
    if (topCurrency === bottomCurrency) {
      if (topCurrency === coins[0]) {
        setBottomCurrency(coins[1]);
      } else {
        setBottomCurrency(coins[0]);
      }
    }
  }, [topCurrency, bottomCurrency]);

  const { data: topTokenAllowance } = useContractRead({
    address: topCurrency?.address,
    chainId: 42161,
    abi: IERC20,
    functionName: 'allowance',
    args: [address, ZAPPER_ADDRESS],
    enabled: !!address,
    watch: true,
  });
  const { config: approveConfig } = usePrepareContractWrite({
    address: topCurrency?.address,
    chainId: 42161,
    abi: IERC20,
    functionName: "approve",
    args: [ZAPPER_ADDRESS, ethers.constants.MaxUint256],
  });
  const { write: doApprove } = useContractWrite(approveConfig);

  const { data: topBalance } = useBalance({
    address: address,
    token: topCurrency?.symbol === "ETH" ? "" : topCurrency?.address,
    watch: true,
    cacheTime: 10_000,
  });

  const { data: bottomBalance } = useBalance({
    address: address,
    token: bottomCurrency?.address,
    watch: true,
    cacheTime: 10_000,
  });

  const { data: amountOut } = useContractRead({
    address: ZAPPER_ADDRESS,
    chainId: 42161,
    abi: ZapperAbi,
    functionName: 'getAmountOut',
    args: [topCurrency?.address, bottomCurrency?.address, parseEther(topAmount)],
    enabled: address && parseEther(topAmount).gt(0),
    watch: true,
  });
  const { data: exchangeRate } = useContractRead({
    address: ZAPPER_ADDRESS,
    chainId: 42161,
    abi: ZapperAbi,
    functionName: 'getAmountOut',
    args: [topCurrency?.address, bottomCurrency?.address, parseEther("1")],
    enabled: address,
    watch: true,
  });

  useEffect(() => {
    if (topAmount === "") {
      setBottomAmount("");
    }
    if (amountOut?.gt(0)) {
      setBottomAmount(formatEther(amountOut))
    }

  }, [amountOut, topAmount])

  const [showSlippageModal, setShowSlippageModal] = useState(false);
  const [rotate, setRotate] = useState(0);
  const [shouldRotate, setShouldRotate] = useState(false);

  const arbitrumPrice = useArbitrumPrice();
  const { empyrealPriceInUSD: empyrealPrice } = useNewEmpyrealPrice();
  const ethPrice = useEthPrice();
  const prices = {
    "EMP": empyrealPrice,
    "ARB": arbitrumPrice,
    "ETH": ethPrice,
  };

  function handleRotate() {
    const tmp = topCurrency;
    setTopCurrency(bottomCurrency);
    setBottomCurrency(tmp);

    setRotate(rotate + 1);
    setShouldRotate(true);
  }

  const rotateClass = rotate % 2 ? "rotate" : "rotate-back";

  const { config: swapConfig } = usePrepareContractWrite({
    address: ZAPPER_ADDRESS,
    chainId: 42161,
    abi: ZapperAbi,
    functionName: "swap",
    args: [topCurrency?.address, bottomCurrency?.address, parseEther(topAmount)],
    overrides: {
      value: topCurrency?.symbol === "ETH" ? parseEther(topAmount) : 0,
    },
    enabled: (topCurrency?.symbol === "ETH" || topTokenAllowance?.gte(parseEther(topAmount || "0"))) && parseEther(topAmount || "0")?.lte(topBalance?.value || "0") && parseEther(topAmount || "0")?.gt(0),
  });
  const { write: doSwap } = useContractWrite(swapConfig, {
    onSuccess: () => setTopAmount(""),
  });

  return (
    <Wrapper>
      <WrapperTitle>
        <Title>Swap</Title>
      </WrapperTitle>
      <WrapperContain>
        <InputWrapper>
          <BalanceLabel
            onClick={() => setTopAmount(topBalance?.formatted)}
            data-domain={`Avail: ${Number(topBalance?.formatted)?.toFixed(4)}`}
          ></BalanceLabel>
          <input value={topAmount} onChange={e => setTopAmount(e.target.value)} style={{ marginTop: '12px' }} />
          <Text size="xs" onClick={() => setTopAmount(topBalance?.formatted)}>MAX</Text>
          <CoinButton setCurrency={setTopCurrency} selectedCoin={topCurrency?.symbol} />
          <span id="dollar"> = ${(prices[topCurrency?.symbol] * Number(topAmount))?.toFixed(4)}</span>
        </InputWrapper>

        <CgArrowsExchangeV
          className={shouldRotate ? rotateClass : ""}
          onClick={handleRotate}
          style={{ color: "#7f8694", fontSize: "30px", cursor: 'pointer' }}
        />

        <InputWrapper>
          <BalanceLabel data-domain={`Avail: ${Number(bottomBalance?.formatted)?.toFixed(4)}`}></BalanceLabel>
          <input disabled value={Number(bottomAmount || 0).toFixed(4)} style={{ marginTop: '12px' }} />
          <Text style={{ visibility: 'hidden' }} size="xs" onClick={() => setBottomAmount(bottomBalance?.formatted)}>MAX</Text>
          <CoinButton setCurrency={setBottomCurrency} selectedCoin={bottomCurrency?.symbol} coinOptions={MAPPING[topCurrency?.symbol]} />
          <span id="dollar"> = ${((prices[bottomCurrency?.symbol] * Number(bottomAmount)))?.toFixed(4) || "."}</span>
        </InputWrapper>

        <TextGroup>
          <TextContainer>
            <>Exchange rate (incl.fees)</>
            <IconGroup>
              <span>{topCurrency?.symbol}/{bottomCurrency?.symbol}</span> {Number(formatEther(exchangeRate)).toFixed(4)}
            </IconGroup>
          </TextContainer>
          {/* <IconGroup2>
            <span>ETH/USDT</span> 1721.21312
          </IconGroup2> */}
          {/* <TextContainer>
            <>Price impact:</>
            <IconGroup>
              <span>0%</span>
              <HiOutlineInformationCircle />
            </IconGroup>
          </TextContainer> */}
          {/* <TextContainer>
            <>Trade routed through</>
            <Progress />
          </TextContainer> */}
          {/* <TextContainer onClick={() => setShowSlippageModal(true)}>
            <>Slippage tolerance</>
            <IconGroup>
              <span>{slippage}%</span>
              <AiOutlineSetting />
            </IconGroup>
          </TextContainer> */}
        </TextGroup>
        {/* <ConnectWallet>{!isConnected ? "ConnectWallet" : "SWAP"}</ConnectWallet> */}
        {topTokenAllowance?.gte(parseEther(topAmount) || 1) || topCurrency?.symbol === "ETH" ?
          <ConnectWallet onClick={() => doSwap()}>SWAP</ConnectWallet> :
          <ConnectWallet onClick={() => doApprove()}>APPROVE</ConnectWallet>
        }
      </WrapperContain>
      <Modal
        open={showSlippageModal}
        onClose={() => {
          setSlippage(slippageInput || slippage);
          setSlippageInput("");
          setShowSlippageModal(false);
        }}
        style={{
          background: "#121212",
          color: "white",
          height: "150px",
          overflow: "auto",
          width: "500px",
        }}
      >
        <p style={{ margin: "10px", }}>SLIPPAGE</p>
        <InputWrapper>
          <input value={slippageInput} onChange={e => setSlippageInput(e.target.value)} type="number" step="0.01" />
          %
        </InputWrapper>
        <ConnectWallet
          style={{ marginTop: '20px', marginLeft: '20%', width: '60%' }}
          onClick={() => setShowSlippageModal(false)}
        >
          Save
        </ConnectWallet>
      </Modal>
    </Wrapper >
  );
};
const Wrapper = styled(Col)`
  max-height: 600px;
  margin: auto;
  max-width: 380px;
  background-color: white;
  box-shadow: 6px 6px 0 #5d6369;
`;
const WrapperTitle = styled(Row)`
  background: #707070;
  padding: 15px;
  width: 100%;
  justify-content: center;
`;
const WrapperContain = styled(Col)`
  padding: 15px;
  width: 100%;
  gap: 20px;
  background-color: #2c2b2b;
`;
const Title = styled(Row)`
  font-weight: bold;
  font-size: 20px;
`;
const Text = styled(Button)`
  font-weight: bold;
  background-color: #55575c;
  padding: 2px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 0px;
  min-width: 60px;
`;
const InputWrapper = styled(Row)`
  position: relative;
  gap: 5px;
  font-size: 1.125rem;
  padding: 4px;
  border: 2px solid #484d56;
  width: 100%;
  justify-content: space-between;
  input {
    font-size: 1.125rem;
    width: 100%;
    background-color: #2c2b2b;
    color: white;
  }
  span {
    color: white;
  }
  #dollar {
    position: absolute;
    top: 60px;
    font-size: 12px;
    left: 4px;
  }
`;

const TextGroup = styled(Col)`
  width: 100%;
  gap: 5px;
  margin-top: 15px;
`;
const TextContainer = styled(Row)`
  font-size: 0.875rem;
  justify-content: space-between;
  width: 100%;
  color: white;
`;
const IconGroup = styled(Row)`
  gap: 4px;
  font-weight: bold;
  span {
    font-size: 12px;
    font-weight: 400;
  }
`;
const IconGroup2 = styled(IconGroup)`
  justify-content: flex-end;
  width: 100%;
  font-size: 14px;
  color: white;
`;
// const Progress = styled(Row)`
//   justify-content: flex-start;
//   font-weight: bold;
//   width: 100%;
// `;
const ConnectWallet = styled(Button)`
  width: 100%;
  justify-content: center;
  font-weight: bold;
  background: #024cde;
  background-color: #55575c;
  border-radius: 0px;

  font-size: 18px;
  color: white;
  border: 0px;
  box-shadow: 3px 3px 0 #464646 !important;
  padding: 8px;
  &:active {
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.6);
  }
`;

const BalanceLabel = styled.label`
  display: block;
  box-sizing: border-box;

  &::after {
    content: attr(data-domain);
    position: absolute;
    color: white;
    top: 4px;
    font-family: arial, helvetica, sans-serif;
    font-size: 12px;
    display: block;
    font-weight: bold;
  }
`
export default QuickSwap;
