import { useState } from "react";
import { HashRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { WagmiConfig, createClient } from 'wagmi'
import { arbitrum } from 'wagmi/chains'
import { publicProvider } from '@wagmi/core/providers/public'
import Header from "./components/Header";
import { configureChains } from "@wagmi/core";
import { InjectedConnector } from 'wagmi/connectors/injected'
import useMediaQuery from '@mui/material/useMediaQuery';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';

import MobileDrawer from "./components/Drawer/mobile-drawer";
import Drawer from "./components/Drawer"
import { DRAWER_WIDTH, TRANSITION_DURATION } from "constants/style";

import Farm from "./views/Farm";
// import Stake from "./views/Stake";
import Dashboard from "./views/Dashboard";
import Delegate from "./views/Delegate";
// import LBP from "./views/LBP";
import Horizon from "./views/Horizon";
import NewFarm from "./views/NewFarm";
import Presale from "./views/Presale";
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import Wrap from "./views/Wrap";
import Zap from "./views/Zap";
import Elysium from "./views/Elysium";
import Farm2 from "./views/Farm2";
import Swap from "./views/Swap";
import Leverage from "./views/Leverage";

import "./styles.scss";
import "./view-base.scss";
import backgroundImg from "./assets/bg/bg.png"


const { chains, provider } = configureChains(
  [arbitrum],
  [publicProvider(),
  jsonRpcProvider({
    rpc: (chain) => ({
      http: 'https://rpc.ankr.com/arbitrum',
    }),
  }),],
)

const client = createClient({
  autoConnect: true,
  connectors: [new InjectedConnector({ chains })],
  provider,
});

const MainDrawer = styled.div`
  width: ${DRAWER_WIDTH};
  flexShrink: 0;
`;

function App() {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const isSmallerScreen = useMediaQuery("(max-width: 960px)");
  const isSmall = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <WagmiConfig client={client}>
      <HashRouter>
        <div className="view-base-root">
          <Header drawe={!isSmallerScreen} handleDrawerToggle={handleDrawerToggle} />
          <MainDrawer>
            {isSmall && <MobileDrawer mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />}
            {!isSmall && <Drawer />}
          </MainDrawer>
          <div
            id="TEST"
            style={{
              padding: theme.spacing(1),
              transition: !isSmallerScreen ? theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: TRANSITION_DURATION,
              }) : theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: TRANSITION_DURATION,
              }),
              height: "100%",
              overflow: "auto",
              marginLeft: isSmallerScreen ? 0 : DRAWER_WIDTH,
              // background: `url(${backgroundImg})`,
              // backgroundSize: "cover",
            }}
          >
            <Routes>
              {/* 
              <Route path="lbp" element={<LBP />} />
              <Route path="presale" element={<Presale />} />*/}
              {/* <Route path="swap" element={<Swap />} /> */}
              <Route path="wrap" element={<Wrap />} />
              <Route path="farm" element={<Farm />} />
              <Route path="newfarm" element={<NewFarm />} />
              <Route path="presale" element={<Presale />} />
              <Route path="horizon" element={<Horizon />} />
              <Route path="delegate" element={<Delegate />} />
              <Route path="zap" element={<Zap />} />
              <Route path="elysium" element={<Elysium />} />
              <Route path="singlestaking" element={<Farm2 />} />
              <Route path="leverage/2xETH" element={<Leverage />} />
              <Route path="*" element={<Dashboard />} />
            </Routes>

          </div>
        </div>

      </HashRouter>
    </WagmiConfig >
  )
}

export default App;
