import { useState, useEffect } from 'react';
import { ethers } from "ethers";
import IFarmABI from "./IFarm.json";
import IERC20ABI from "./IERC20.json";
import { useContractRead, useProvider } from 'wagmi'
import IRouterABI from "./IRouter.json";

const FARM_ADDRESS = "0x98C35De1A27c7bdC82cE6aCADa37249934fFc08A";
const FIRM_PAIR_ADDRESS = "0x87E65159EdAfAe4bb1CCD0c94c7Ec9427409B370";
const CAMELOT_ROUTER_ADDRESS = "0xc873fEcbd354f5A56E00E710B90EF4201db2448d";
const FIRMAMENT_ADDRESS = "0x368F6d735F3Fc8Aa0568D2B7aB275cB828B79709";
const USDC_ADDRESS = "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8";
const ARBITRUM_ADDRESS = "0x912ce59144191c1204e64559fe8253a0e49e6548";
const EMPYREAL_ADDRESS = "0x0DDCE00654f968DeD59A444da809F2B234047aB1";
const egARB_ADDRESS = "0x53e131a94Df92787a080F61FE67Ce1EA6640EA4C";

const EMPY_PAIR_ADDRESS = "0x06675843400F2267060ee886C9088fF498f7c8eC";

const FIRM_PER_SECOND = 0.0017;

const pools = {
    0: {
        allocPoints: 1000000,
    },
    1: {
        allocPoints: 200000,
    }
}
const TOTAL_ALLOC_POINTS = 1200000;

export function useFirmPrice() {
    const { data: firmPrice } = useContractRead({
        address: CAMELOT_ROUTER_ADDRESS,
        chainId: 42161,
        abi: IRouterABI,
        functionName: 'getAmountsOut',
        args: [ethers.utils.parseEther("1"), [FIRMAMENT_ADDRESS, USDC_ADDRESS]],
        watch: true,
    });

    return firmPrice && firmPrice[1].toNumber() / 1000000;
}

export function useArbitrumPrice() {
    const { data: arbitrumPrice } = useContractRead({
        address: CAMELOT_ROUTER_ADDRESS,
        chainId: 42161,
        abi: IRouterABI,
        functionName: 'getAmountsOut',
        args: [ethers.utils.parseEther("1"), [ARBITRUM_ADDRESS, USDC_ADDRESS]],
        watch: true,
    });

    return arbitrumPrice && arbitrumPrice[1].toNumber() / 1000000;
}

export function useEmpyrealPrice() {
    const [empPrice, setEmpPrice] = useState(0);
    const { data: empyrealPriceInEgArb } = useContractRead({
        address: CAMELOT_ROUTER_ADDRESS,
        chainId: 42161,
        abi: IRouterABI,
        functionName: 'getAmountsOut',
        args: [ethers.utils.parseEther("1"), [EMPYREAL_ADDRESS, egARB_ADDRESS]],
        watch: true,
    });
    const arbPrice = useArbitrumPrice();
    useEffect(() => {
        setEmpPrice(ethers.utils.formatEther(empyrealPriceInEgArb?.[1]) * arbPrice);
    }, [empyrealPriceInEgArb, arbPrice]);

    return empPrice;
}

export function usePool1APR(account = "0xF548a58DB6d86d466acd00Fc0F6De3b39Ea129D7") {
    const { data: pairBalance } = useContractRead({
        address: FIRMAMENT_ADDRESS,
        chainId: 42161,
        abi: IERC20ABI,
        functionName: 'balanceOf',
        args: [FIRM_PAIR_ADDRESS],
    });
    const { data: lpTotalSupply } = useContractRead({
        address: FIRM_PAIR_ADDRESS,
        chainId: 42161,
        abi: IERC20ABI,
        functionName: 'totalSupply',
        args: [],
        watch: true,
    });
    const { data: farmLPBalance } = useContractRead({
        address: FIRM_PAIR_ADDRESS,
        chainId: 42161,
        abi: IERC20ABI,
        functionName: 'balanceOf',
        args: [FARM_ADDRESS],
        watch: true,
    });
    const { data: userInfo } = useContractRead({
        address: FARM_ADDRESS,
        chainId: 42161,
        abi: IFarmABI,
        functionName: 'userInfo',
        args: [1, account],
        watch: true,
    });

    const firmPrice = useFirmPrice();

    const lpValue = pairBalance * firmPrice * 2 / 1e18;
    const pricePerLpToken = lpValue * 1e18 / lpTotalSupply;

    const percentOfYield = userInfo?.amount / farmLPBalance;

    const valueOfBalance = percentOfYield * lpValue;

    const dollarPerSecond = firmPrice * FIRM_PER_SECOND * 2 / 12;
    const yearlyIncome = dollarPerSecond * 3600 * 24 * 365 * percentOfYield;
    const userValue = userInfo?.amount * pricePerLpToken / 1e18;

    const APR = yearlyIncome / userValue * 100;

    return { APR };
}

export function usePool0APR(account = "0xF548a58DB6d86d466acd00Fc0F6De3b39Ea129D7") {
    const { data: pairBalance } = useContractRead({
        address: EMPYREAL_ADDRESS,
        chainId: 42161,
        abi: IERC20ABI,
        functionName: 'balanceOf',
        args: [EMPY_PAIR_ADDRESS],
    });
    const { data: lpTotalSupply } = useContractRead({
        address: EMPY_PAIR_ADDRESS,
        chainId: 42161,
        abi: IERC20ABI,
        functionName: 'totalSupply',
        args: [],
        watch: false,
    });
    const { data: farmLPBalance } = useContractRead({
        address: EMPY_PAIR_ADDRESS,
        chainId: 42161,
        abi: IERC20ABI,
        functionName: 'balanceOf',
        args: [FARM_ADDRESS],
        watch: false,
    });
    const { data: userInfo } = useContractRead({
        address: FARM_ADDRESS,
        chainId: 42161,
        abi: IFarmABI,
        functionName: 'userInfo',
        args: [0, account],
        watch: false,
    });

    const empyrealPrice = useEmpyrealPrice();
    const firmPrice = useFirmPrice();

    const lpValue = pairBalance * empyrealPrice * 2 / 1e18;
    const pricePerLpToken = lpValue * 1e18 / lpTotalSupply;

    const percentOfYield = userInfo?.amount / farmLPBalance;

    const valueOfBalance = percentOfYield * lpValue;

    const dollarPerSecond = firmPrice * FIRM_PER_SECOND * 10 / 12;
    const yearlyIncome = dollarPerSecond * 3600 * 24 * 365 * percentOfYield;
    const userValue = userInfo?.amount * pricePerLpToken / 1e18;

    const APR = yearlyIncome / userValue * 100;

    return { APR };
}


export function useRewardRate(poolId, account) {
    const [apr, setApr] = useState(0);
    const { data: poolInfo } = useContractRead({
        address: FARM_ADDRESS,
        chainId: 42161,
        abi: IFarmABI,
        functionName: 'poolInfo',
        args: [poolId],
        watch: true,
    });
    const { data: userInfo } = useContractRead({
        address: FARM_ADDRESS,
        chainId: 42161,
        abi: IFarmABI,
        functionName: 'userInfo',
        args: [poolId, account],
        watch: true,
    });

    const { data: totalSupply } = useContractRead({
        address: poolInfo?.token,
        chainId: 42161,
        abi: IERC20ABI,
        functionName: 'balanceOf',
        args: [FARM_ADDRESS],
        watch: true,
        enabled: poolInfo,
    });

    useEffect(() => {
        const rewardPercent = pools[poolId].allocPoints / TOTAL_ALLOC_POINTS;
        setApr(rewardPercent * userInfo?.amount / totalSupply);
    }, [userInfo, totalSupply, poolId])

    return apr
}


export function usePendingBalance(poolId, account) {
    const [pending, setPending] = useState(null);
    const { data: poolInfo } = useContractRead({
        address: FARM_ADDRESS,
        chainId: 42161,
        abi: IFarmABI,
        functionName: 'poolInfo',
        args: [poolId],
        watch: true,
    });
    const { data: userInfo } = useContractRead({
        address: FARM_ADDRESS,
        chainId: 42161,
        abi: IFarmABI,
        functionName: 'userInfo',
        args: [poolId, account],
        watch: true,
    });
    let accFirmamentPerShare = poolInfo?.accFirmamentPerShare;
    const { data: totalSupply } = useContractRead({
        address: poolInfo?.token,
        chainId: 42161,
        abi: IERC20ABI,
        functionName: 'balanceOf',
        args: [FARM_ADDRESS],
        watch: true,
        enabled: poolInfo,
    });

    // const block = provider.getBlock('latest');
    // console.log('block', block);
    const timestamp = Math.floor(Date.now() / 1000);

    const { data: generatedReward } = useContractRead({
        address: FARM_ADDRESS,
        chainId: 42161,
        abi: IFarmABI,
        functionName: 'getGeneratedReward',
        args: [poolInfo?.lastRewardTime, timestamp],
        watch: true,
        enabled: poolInfo,
    });
    const _firmReward = generatedReward;
    useEffect(() => {
        if (userInfo && poolInfo && _firmReward && totalSupply && accFirmamentPerShare) {
            const accFirmamentPerShareNew = poolInfo?.accFirmamentPerShare.add((_firmReward).mul(ethers.utils.parseEther("1")).div(totalSupply.add("1")));
            setPending(userInfo.amount.mul(accFirmamentPerShareNew).div(ethers.utils.parseEther("1")).sub(userInfo.rewardDebt));
        }
    }, [poolId, userInfo, poolInfo, _firmReward, totalSupply, accFirmamentPerShare]);

    return pending
}
