import React, { useCallback, useState } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import OutlinedInput from '@mui/material/OutlinedInput';
import Zoom from '@mui/material/Zoom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { InputAdornment } from '@mui/material';
import { useAccount, useConnect } from "wagmi";
import Button from "../../components/Button";
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useContractRead, usePrepareContractRead, usePrepareContractWrite, useContractWrite } from 'wagmi'
import { writeContract } from '@wagmi/core';
import IArbitrum from "./IArbitrum.json";
import { ethers } from "ethers";
import { parseEther } from "utils/etherUtils";
import { useSigner, useContract } from 'wagmi'

import { CONTROLLER_ADDRESS } from "constants";

import "./stake.scss";

import IERC20 from "./IERC20.json";
import { useBalance } from 'wagmi'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'green',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const ARBITRUM_ADDRESS = "0x912ce59144191c1204e64559fe8253a0e49e6548";
const egARB_ADDRESS = "0x53e131a94Df92787a080F61FE67Ce1EA6640EA4C";

function Delegate() {
    const { address } = useAccount();
    const { connect } = useConnect();
    const { data: signer } = useSigner();
    // const Contract = new ethers.Contract(ARBITRUM_ADDRESS, IArbitrum, signer)
    const Contract = useContract({
        address: ARBITRUM_ADDRESS,
        abi: IArbitrum,
        signerOrProvider: signer,
    })

    async function doDelegate() {
        try {
            await Contract.delegate(CONTROLLER_ADDRESS)
        } catch (e) {
        }
    }
    console.log('doDelegate', signer);

    // const { write: doDelegate } = writeContract({
    //     mode: 'recklesslyUnprepared',
    //     address: ARBITRUM_ADDRESS,
    //     chainId: 42161,
    //     abi: IERC20,
    //     functionName: "delegates",
    //     args: [CONTROLLER_ADDRESS],
    // });
    // console.log('doDelegate', doDelegate);

    return (
        <div className="stake-view">
            <Zoom in={true}>
                <div className="farm-card">
                    <Grid className="farm-card-grid" container direction="column" spacing={2}>
                        <Grid item>
                            <div className="farm-card-header">
                                <p className="farm-card-header-title">Delegate</p>
                            </div>
                        </Grid>
                    </Grid>

                    <div className="farm-card-area">
                        {!address && (
                            <div className="farm-card-wallet-notification">
                                <div className="farm-card-wallet-connect-btn" onClick={connect}>
                                    <p>Connect Wallet</p>
                                </div>
                                <p className="farm-card-wallet-desc-text">Connect your wallet to wrap</p>
                            </div>
                        )}
                        {address && (
                            <div>
                                <div className="farm-card-action-area">
                                    <div className="farm-card-action-row">
                                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
                                            <Box sx={{
                                                p: 1,
                                                m: 1,
                                            }}>
                                                <Button action={() => doDelegate()} text={"Delegate"}></Button>
                                            </Box>
                                        </Box>
                                    </div>

                                </div>

                            </div>
                        )}

                        <div className="farm-card-action-help-text">
                            <h2 style={{ marginLeft: '10px', color: 'white', textAlign: 'left' }}>Note:</h2>
                            <p style={{ textAlign: 'left', color: 'white' }}>
                                This allows you to delegate your arbitrum to the current controller of Empyreal.
                            </p>
                            <p style={{ textAlign: 'left', color: 'white' }}>
                                You will still have full control of your ARB but it will increase Empyreal's voting power.
                            </p>
                        </div>
                    </div>

                </div>
            </Zoom >
        </div >
    );
}

export default Delegate;
