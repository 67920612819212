import React, { useState } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import OutlinedInput from '@mui/material/OutlinedInput';
import Zoom from '@mui/material/Zoom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { InputAdornment } from '@mui/material';
import { useAccount, useConnect } from "wagmi";
import Button from "../../components/Button";
import { useContractRead, usePrepareContractWrite, useContractWrite } from 'wagmi'
import { ethers } from "ethers";
import { parseEther } from "utils/etherUtils";
import { ICamelotZap } from "utils/abis";

import "./stake.scss";

import IERC20 from "./IERC20.json";
import { useBalance } from 'wagmi'

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ARBITRUM_ADDRESS = "0x912ce59144191c1204e64559fe8253a0e49e6548";
const ZAPPER_ADDRESS = "0xE5A2B4Ea74361e3625c190447695E2Dc275FF001";

function Zap() {
    const { address } = useAccount();
    const { connect } = useConnect();
    const [quantity, setQuantity] = useState("");
    const [isWrap, setIsWrap] = useState(true);
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setIsWrap(newValue === 0);
        setValue(newValue);
    };

    const { data: arbBalance } = useBalance({
        address,
        token: ARBITRUM_ADDRESS,
        watch: true,
    });

    const { data: arbAllowance } = useContractRead({
        address: ARBITRUM_ADDRESS,
        chainId: 42161,
        abi: IERC20,
        functionName: 'allowance',
        args: [address, ZAPPER_ADDRESS],
        enabled: address,
        watch: true,
    });
    const { config: approveConfig } = usePrepareContractWrite({
        address: ARBITRUM_ADDRESS,
        chainId: 42161,
        abi: IERC20,
        functionName: "approve",
        args: [ZAPPER_ADDRESS, ethers.constants.MaxUint256],
    });
    const { write: doApproveArb } = useContractWrite(approveConfig);
    const doApprove = doApproveArb;
    const hasApproved = arbAllowance && !arbAllowance.eq(0) && arbAllowance.gte(parseEther(quantity));

    const tokenBalance = arbBalance;
    const SYMBOL = "ARB";
    const actionText = "ZAP";

    const { config: zapConfig } = usePrepareContractWrite({
        address: ZAPPER_ADDRESS,
        chainId: 42161,
        abi: ICamelotZap,
        functionName: "addLiquidityEMP",
        args: [parseEther(quantity)],
        enabled: isWrap && !!quantity,
    });
    const { write: doEmpZap } = useContractWrite(zapConfig);
    const { config: zapFirmConfig } = usePrepareContractWrite({
        address: ZAPPER_ADDRESS,
        chainId: 42161,
        abi: ICamelotZap,
        functionName: "addLiquidityFIRM",
        args: [parseEther(quantity)],
        enabled: !isWrap && !!quantity,
    });
    const { write: doFirmZap } = useContractWrite(zapFirmConfig);
    const doAction = isWrap ? doEmpZap : doFirmZap;

    return (
        <div className="stake-view">
            <Zoom in={true}>
                <div className="farm-card">
                    <Grid className="farm-card-grid" container direction="column" spacing={2}>
                        <Grid item>
                            <div className="farm-card-header">
                                <p className="farm-card-header-title">ZAP</p>
                            </div>
                        </Grid>
                    </Grid>

                    <div className="farm-card">
                        <Grid item>
                            <div className="farm-card-metrics">
                                <Grid container spacing={2}>
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        sx={{
                                            '& .MuiTabs-indicator': { backgroundColor: 'white !important' },
                                            '& .MuiTab-root': { color: 'gray' },
                                            '& .Mui-selected': { color: 'white !important' },
                                        }}
                                    >
                                        <Tab label="EMP/egARB" {...a11yProps(0)} />
                                        {/* <Tab label="FIRM/USDC" {...a11yProps(1)} /> */}
                                    </Tabs>
                                </Grid>
                            </div>
                        </Grid>
                    </div>

                    <div className="farm-card-area">
                        {!address && (
                            <div className="farm-card-wallet-notification">
                                <div className="farm-card-wallet-connect-btn" onClick={connect}>
                                    <p>Connect Wallet</p>
                                </div>
                                <p className="farm-card-wallet-desc-text">Connect your wallet to wrap</p>
                            </div>
                        )}
                        {address && (
                            <div>
                                <div className="farm-card-action-area">
                                    <div className="farm-card-action-row">
                                        <OutlinedInput
                                            type="number"
                                            placeholder="Amount"
                                            className="farm-card-action-input"
                                            value={quantity}
                                            onChange={e => setQuantity(e.target.value)}
                                            // labelWidth={0}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {/* <div onClick={setMax} className="farm-card-action-input-btn">
                                                        <p>Max</p>
                                                    </div> */}
                                                </InputAdornment>
                                            }
                                        />
                                    </div>
                                    <div className="farm-card-action-row">
                                        <Box sx={{ display: 'flex-right', color: 'white', textAlign: 'right', marginRight: '25px', width: '100%', justifyContent: 'space-evenly' }}>
                                            <p style={{ cursor: 'pointer' }} onClick={() => setQuantity(tokenBalance?.formatted)}>
                                                Balance: {tokenBalance?.formatted} {SYMBOL}</p>
                                        </Box>
                                    </div>

                                    <div className="farm-card-action-row">
                                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
                                            <Box sx={{
                                                p: 1,
                                                m: 1,
                                            }}>
                                                {hasApproved ? <Button action={() => doAction()} text={actionText}></Button> : <Button action={() => doApprove()} text={"APPROVE"}></Button>}
                                            </Box>
                                        </Box>
                                    </div>

                                </div>

                            </div>
                        )}
                        <div className="farm-card-action-help-text">
                            <h2 style={{ marginLeft: '10px', color: 'white', textAlign: 'left' }}>Notes:</h2>
                            <p style={{ textAlign: 'left', color: 'white' }}>
                                ZAP your Arbitrum tokens directly into Camelot LP.  Then you can deposit them in the farms to earn yield!
                            </p>
                        </div>
                    </div>
                </div>
            </Zoom >
        </div >
    );
}

export default Zap;
