import { Modal } from "@nextui-org/react";
// import axios from "axios";
import { useState, useEffect } from "react";
import styled from "styled-components";

import { Col, Row } from "./element";
import { coins } from "./constants";

const ModalContainer = ({ visible, setVisible, setCoinSelect, setCoinImage, setCurrency, coinOptions }) => {
  const [search, setSearch] = useState("");
  const [crypto, setCrypto] = useState(coins);
  const handleClick = (val) => {
    setCurrency(val);
    setCoinSelect(val.symbol);
    setCoinImage(val.icon);
    setVisible(false);
  };
  useEffect(() => {
    if (!!coinOptions) {
      setCrypto(coins.filter(coin => coinOptions.has(coin.symbol)));
    }
  }, [coinOptions]);

  // useEffect(() => {
  //   axios
  //     .get(`https://api.coinstats.app/public/v1/coins?skip=0&limit=100¤cy=INR`)
  //     .then((res) => {
  //       console.log("res", res.data.coins);
  //       setCrypto(res.data.coins);
  //     });
  // }, []);
  return (
    <Modal
      noPadding
      open={visible}
      style={{
        background: "#121212",
        color: "white",
        height: "550px",
        overflow: "auto",
        width: "500px",
      }}
    >
      <InputWrapper
        type="text"
        placeholder="Search..."
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      {crypto
        .filter((val) => {
          return val.name.toLowerCase().includes(search.toLowerCase());
        })
        .map((val, id) => {
          return (
            <Wrapper onClick={() => handleClick(val)} key={id}>
              <DivWrapperRow className="logo">
                <img src={val.icon} alt="logo" width="30px" />
                <DivWrapperCol>
                  <span>{val.name}</span>
                  <p>{val.symbol}</p>
                </DivWrapperCol>
              </DivWrapperRow>
            </Wrapper>
          );
        })}
    </Modal>
  );
};

const Wrapper = styled(Row)`
  padding: 10px 20px;
`;
const DivWrapperCol = styled(Col)`
  align-items: flex-start;
  p {
    font-size: 12px;
    margin: 0px;
  }
`;
const DivWrapperRow = styled(Row)`
  gap: 10px;
`;
const InputWrapper = styled.input`
  font-size: 1.125rem;
  margin: 10px auto;
  background-color: #2c2b2b;
  color: white;
  height: 40px;
  width: 90%;
  padding: 10px;
  border-radius: 14px;
`;
export default ModalContainer;
