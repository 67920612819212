// import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { useWeb3Context } from "../../../hooks";
// import { IReduxState } from "../../../store/slices/state.interface";
// import { IPendingTxn } from "../../../store/slices/pending-txns-slice";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import { AVAILABLE_CHAINS } from "../../../constants/blockchain";
import { useAccount, useConnect, useDisconnect, useNetwork, useSwitchNetwork } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import "./connect-menu.scss";

function ConnectMenu() {
    const { chain } = useNetwork();
    const { connect } = useConnect({
        connector: new InjectedConnector(),
    })
    const { address, isConnected } = useAccount()
    const { disconnect } = useDisconnect();
    const { switchNetwork } = useSwitchNetwork({
        chainId: 42161,
    });


    let buttonText = "Connect Wallet";
    let clickFunc = connect;
    let buttonStyle = {};

    if (isConnected && chain?.id !== 42161) {
        buttonText = "Wrong network";
        buttonStyle = { backgroundColor: "rgb(255, 67, 67)" };
        clickFunc = switchNetwork;
    } else if (isConnected) {
        buttonText = `0x${address.slice(-4)}`
        buttonText = "Disconnect"
        clickFunc = disconnect;
    }

    return (
        <div className="connect-button" style={buttonStyle} onClick={() => clickFunc()}>
            <p>{buttonText}</p>
        </div>
    );
}

export default ConnectMenu;
