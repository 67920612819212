import { ethers } from "ethers";
import { useState, useEffect } from "react";

import { useNewEmpyrealPrice, useEmpyrealEgArbLPPrice, useFirmamentPrice, useFirmUSDCLPPrice } from "./tokenPrices";
import { IERC20, IFarm, IFarm2 } from "utils/abis";
import { useContractRead } from "wagmi";
import { NEW_FARM_ADDRESS as FARM_ADDRESS, EMP2_FARM_ADDRESS } from "constants";
import { formatEther } from "utils/etherUtils";
import { FIRM_PER_SECOND } from "constants";


const pools = {
    0: {
        allocPoints: 100,
    },
    1: {
        allocPoints: 20,
    }
}
const TOTAL_ALLOC_POINTS = 120;



export function useRewardRate(poolId, account = "0xF548a58DB6d86d466acd00Fc0F6De3b39Ea129D7") {
    const [apr, setApr] = useState(0);
    const firmamentPrice = useFirmamentPrice();
    const { data: poolInfo } = useContractRead({
        address: FARM_ADDRESS,
        chainId: 42161,
        abi: IFarm,
        functionName: 'poolInfo',
        args: [poolId],
        watch: true,
    });
    const { data: userInfo } = useContractRead({
        address: FARM_ADDRESS,
        chainId: 42161,
        abi: IFarm,
        functionName: 'userInfo',
        args: [poolId, account],
        watch: true,
    });

    const { data: totalSupply } = useContractRead({
        address: poolInfo?.token,
        chainId: 42161,
        abi: IERC20,
        functionName: 'balanceOf',
        args: [FARM_ADDRESS],
        watch: true,
        enabled: poolInfo,
    });
    const lp0Price = useEmpyrealEgArbLPPrice();
    const lp1Price = useFirmUSDCLPPrice();
    const tokenPrice = poolId === 0 ? lp0Price : lp1Price;

    useEffect(() => {
        const rewardShare = pools[poolId].allocPoints / TOTAL_ALLOC_POINTS;
        const totalReward = rewardShare * FIRM_PER_SECOND * firmamentPrice * 3600 * 24 * 365;
        const userReward = totalReward * userInfo?.amount / totalSupply;
        const stakedValue = tokenPrice * formatEther(userInfo?.amount);
        setApr(userReward / stakedValue);
    }, [userInfo, totalSupply, poolId, firmamentPrice, tokenPrice])

    return apr
}


export function useRewardRateNew(poolId = 0, account = "0xF548a58DB6d86d466acd00Fc0F6De3b39Ea129D7") {
    console.log(poolId, "poolId");
    const EMP_PER_SECOND = 0.0048225;
    const [apr, setApr] = useState(0);
    const { empyrealPriceInUSD: empPrice } = useNewEmpyrealPrice();
    console.log("NEW FARM", FARM_ADDRESS);
    const { data: poolInfo } = useContractRead({
        address: EMP2_FARM_ADDRESS,
        chainId: 42161,
        abi: IFarm2,
        functionName: 'poolInfo',
        args: [poolId],
        watch: true,
    });
    const { data: userInfo } = useContractRead({
        address: EMP2_FARM_ADDRESS,
        chainId: 42161,
        abi: IFarm2,
        functionName: 'userInfo',
        args: [poolId, account],
        watch: true,
    });
    console.log("userInfo", empPrice)

    const { data: totalSupply } = useContractRead({
        address: poolInfo?.token,
        chainId: 42161,
        abi: IERC20,
        functionName: 'balanceOf',
        args: [EMP2_FARM_ADDRESS],
        watch: true,
        enabled: poolInfo,
    });
    const tokenPrice = empPrice;

    useEffect(() => {
        const rewardShare = 1;
        const totalReward = rewardShare * EMP_PER_SECOND * empPrice * 3600 * 24 * 365;
        const userReward = totalReward * userInfo?.amount / totalSupply?.sub(ethers.utils.parseEther("25000"));
        const stakedValue = tokenPrice * formatEther(userInfo?.amount);
        setApr(userReward / stakedValue);
    }, [userInfo, totalSupply, poolId, empPrice, tokenPrice])

    return apr
}
