const EMP2_FARM_ADDRESS = "0xbd32C67b664Aa0403fa9395f5F49f19afF20Ec42";
const NEW_EMPYREAL_ADDRESS = "0x772598E9e62155D7fDFe65FdF01EB5a53a8465BE";

const FARM_ADDRESS = "0x98C35De1A27c7bdC82cE6aCADa37249934fFc08A";
const NEW_FARM_ADDRESS = "0x15084E92785027D4D4918CAbfa11281fC15bF9AC";
const STAGING_ADDRESS = "0xD780259Cd78af74728521125F95C7E1d6938c7e7";
const FIRM_PAIR_ADDRESS = "0x87E65159EdAfAe4bb1CCD0c94c7Ec9427409B370";
const CAMELOT_ROUTER_ADDRESS = "0xc873fEcbd354f5A56E00E710B90EF4201db2448d";
const FIRMAMENT_ADDRESS = "0x368F6d735F3Fc8Aa0568D2B7aB275cB828B79709";
const USDC_ADDRESS = "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8";
const ARBITRUM_ADDRESS = "0x912ce59144191c1204e64559fe8253a0e49e6548";
const EMPYREAL_ADDRESS = "0x0DDCE00654f968DeD59A444da809F2B234047aB1";
const egARB_ADDRESS = "0x53e131a94Df92787a080F61FE67Ce1EA6640EA4C";
// const HORIZON_ADDRESS = "0x31bfbaF6fE76B0030dc94c12b8a310074A528912";
const HORIZON_ADDRESS = "0x71d2009460383c970c08b0e37cc8f029bce5bbcd";
const OLD_HORIZON_ADDRESS = "0x31bfbaF6fE76B0030dc94c12b8a310074A528912";
const CONTROLLER_ADDRESS = "0xF548a58DB6d86d466acd00Fc0F6De3b39Ea129D7";

const EMPY_PAIR_ADDRESS = "0x06675843400F2267060ee886C9088fF498f7c8eC";
const TREASURY_ADDRESS = "0x872BdabE732ebf8966C8e6a75140D9CbEE264fd3";

const FIRM_PER_SECOND = 0.0017;

const WETH_ADDRESS = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";

export {
    FARM_ADDRESS,
    NEW_FARM_ADDRESS,
    STAGING_ADDRESS,
    FIRM_PAIR_ADDRESS,
    CAMELOT_ROUTER_ADDRESS,
    FIRMAMENT_ADDRESS,
    USDC_ADDRESS,
    ARBITRUM_ADDRESS,
    EMPYREAL_ADDRESS,
    egARB_ADDRESS,
    EMPY_PAIR_ADDRESS,
    FIRM_PER_SECOND,
    HORIZON_ADDRESS,
    TREASURY_ADDRESS,
    OLD_HORIZON_ADDRESS,
    CONTROLLER_ADDRESS,
    EMP2_FARM_ADDRESS,
    NEW_EMPYREAL_ADDRESS,
    WETH_ADDRESS,
}
