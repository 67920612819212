import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from "../../assets/icons/hamburger.svg";
// import TimeMenu from "./time-menu";
import ConnectButton from "./connect-button";
// import WrapButton from "./wrap-button";
// import makeStyles from '@mui/styles/makeStyles';
import "./header.scss";
import { DRAWER_WIDTH, TRANSITION_DURATION } from "constants/style";
import { useNetwork } from 'wagmi'
// import { useWeb3Context } from "../../hooks";
// import { Networks } from "../../constants/blockchain";
import NetworkMenu from "./network-menu";
import { css } from '@emotion/css'
import { alpha, styled } from '@mui/material/styles';
import { default as styledd } from 'styled-components';
import { useTheme } from '@mui/material/styles';

const Wrapper = styledd.div`
  background-color: transparent !important;
`;

const StyledAppBar = styled(AppBar)`
    z-index: 10;
    background: transparent;
    align-items: flex-end;
    backdrop-filter: none;
    justify-content: flex-end;
`;

const classes = css`
  transition: margin-left ${TRANSITION_DURATION};
  margin-left: 0;
`;

function Header({ handleDrawerToggle, drawe }) {
  // const classes = useStyles();
  const theme = useTheme();
  const isVerySmallScreen = useMediaQuery("(max-width: 548px)");
  const isWrapShow = useMediaQuery("(max-width: 710px)");

  const { chain } = useNetwork();
  // const chainID = chain.id;

  const aboveSmall = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Wrapper style={{
      transition: drawe ? theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: TRANSITION_DURATION,
      }) : theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: TRANSITION_DURATION,
      }),
      marginLeft: drawe ? DRAWER_WIDTH : 0,
    }}>
      <AppBar
        className={classes.appBar}
        position="sticky"
        elevation={0}
        sx={{
          width: aboveSmall && "100%",
          padding: aboveSmall && "20px 0 30px 0",
          zIndex: 10,
          background: "transparent",
          alignItems: "flex-end",
          backdropFilter: "none",
          justifyContent: "flex-end",
        }}
      >
        <Toolbar disableGutters className="dapp-topbar">
          <div onClick={handleDrawerToggle} className="dapp-topbar-slider-btn">
            <img src={MenuIcon} alt="" />
          </div>
          <div className="dapp-topbar-btns-wrap">
            {/* {!isWrapShow && chainID === Networks.AETH && <TimeMenu />}
                      {chainID === Networks.AETH && <WrapButton />}
                      {!isVerySmallScreen && <NetworkMenu />} */}
            <ConnectButton />
          </div>
        </Toolbar>
      </AppBar >
    </Wrapper>
  );
}

export default Header;
