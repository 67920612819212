import React, { useCallback, useState, useEffect } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useBalance } from 'wagmi'
import OutlinedInput from '@mui/material/OutlinedInput';
import Zoom from '@mui/material/Zoom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { InputAdornment } from '@mui/material';
import { useAccount, useConnect } from "wagmi";

import Button from "../../components/Button";
import { useContractRead, usePrepareContractWrite, useContractWrite } from 'wagmi'
import { ethers } from "ethers";
import QuickSwap from "components/Swap"
import "./swap.scss";

function Swap() {
    const { address } = useAccount();
    const { connect } = useConnect();

    return (
        <div className="farm-view">
            <Zoom in={true}>
                <div className="farm-card">
                    <div className="farm-card-area">
                        {!address && (
                            <div className="farm-card-wallet-notification">
                                <div className="farm-card-wallet-connect-btn" onClick={connect}>
                                    <p>Connect Wallet</p>
                                </div>
                                <p className="farm-card-wallet-desc-text">Connect your wallet</p>
                            </div>
                        )}
                        {address && (
                            <QuickSwap />
                        )}
                    </div>
                </div>
            </Zoom >
        </div>
    );
}

export default Swap;
