// import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { useWeb3Context } from "../../../hooks";
// import { IReduxState } from "../../../store/slices/state.interface";
// import { IPendingTxn } from "../../../store/slices/pending-txns-slice";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import { AVAILABLE_CHAINS } from "../../../constants/blockchain";
import { useAccount, useConnect, useDisconnect, useNetwork, useSwitchNetwork } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import "./button.scss";

const disabled = {
    cursor: "not-allowed",
    pointerEvents: "none",
    background: "gray",
    color: "#977777 !important"
};

function Button({ action, text, selected, style, isDisabled }) {

    return (
        <button
            className="connect-button"
            onClick={() => action()}
            style={{
                background: selected ? "rgba(255, 255, 255, 0.2)" : "rgba(48, 42, 42, 0.2)",
                ...style,
                ...(isDisabled ? disabled : {}),
            }}
        >
            <p style={isDisabled ? disabled : {}}>{text}</p>

        </button>
    );
}

export default Button;
