import React, { useCallback, useState, useEffect } from "react";
import Countdown from 'react-countdown';
import Zoom from '@mui/material/Zoom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import { InputAdornment } from '@mui/material';
import { InjectedConnector } from 'wagmi/connectors/injected'
import { useAccount, useConnect } from "wagmi";
import Button from "../../components/Button";
import { useContractRead, usePrepareContractWrite, useContractWrite } from 'wagmi'
import { ethers } from "ethers";
import { useBalance } from 'wagmi'

import { useHorizonTVL, useHorizonAPR } from "utils/tvl";
import { formatEther, parseEther } from "utils/etherUtils";
import { IERC20, IHorizon, TreasuryABI } from "utils/abis";
import { HORIZON_ADDRESS, FIRMAMENT_ADDRESS } from "constants";

import "./horizon.scss";
import { TREASURY_ADDRESS } from "constants";


function Horizon() {
    const { address } = useAccount();
    const { connect } = useConnect({
        connector: new InjectedConnector(),
    })
    const [quantity, setQuantity] = useState("");
    const horizonTVL = useHorizonTVL();
    const apr = useHorizonAPR();

    const { data: tokenAllowance } = useContractRead({
        address: FIRMAMENT_ADDRESS,
        chainId: 42161,
        abi: IERC20,
        functionName: 'allowance',
        args: [address, HORIZON_ADDRESS],
        enabled: address,
        watch: true,
    });
    const { data: member } = useContractRead({
        address: HORIZON_ADDRESS,
        chainId: 42161,
        abi: IHorizon,
        functionName: 'members',
        args: [address],
        enabled: !!address,
    });
    const { data: epoch } = useContractRead({
        address: HORIZON_ADDRESS,
        chainId: 42161,
        abi: IHorizon,
        functionName: 'epoch',
        args: [],
    });
    const { data: nextEpochPoint } = useContractRead({
        address: HORIZON_ADDRESS,
        chainId: 42161,
        abi: IHorizon,
        functionName: 'nextEpochPoint',
        args: [],
    });

    const { data: horizonBalance } = useContractRead({
        address: HORIZON_ADDRESS,
        chainId: 42161,
        abi: IHorizon,
        functionName: 'balanceOf',
        args: [address],
        enabled: !!address,
        watch: true,
    });
    const { data: earned } = useContractRead({
        address: HORIZON_ADDRESS,
        chainId: 42161,
        abi: IHorizon,
        functionName: 'earned',
        args: [address],
        enabled: !!address,
        watch: true,
    });
    const { data: canClaimReward } = useContractRead({
        address: HORIZON_ADDRESS,
        chainId: 42161,
        abi: IHorizon,
        functionName: 'canClaimReward',
        args: [address],
        enabled: !!address,
    });
    const { data: canWithdraw } = useContractRead({
        address: HORIZON_ADDRESS,
        chainId: 42161,
        abi: IHorizon,
        functionName: 'canWithdraw',
        args: [address],
        enabled: !!address,
        watch: true,
    });

    const { data: firmBalance } = useBalance({
        address,
        token: FIRMAMENT_ADDRESS,
        watch: true,
    });
    const { config: approveConfig } = usePrepareContractWrite({
        address: FIRMAMENT_ADDRESS,
        chainId: 42161,
        abi: IERC20,
        functionName: "approve",
        args: [HORIZON_ADDRESS, ethers.constants.MaxUint256],
    });
    const { write: doApprove } = useContractWrite(approveConfig);
    const hasApproved = tokenAllowance && tokenAllowance.gt(parseEther("100000"));

    const { config: stakeConfig } = usePrepareContractWrite({
        address: HORIZON_ADDRESS,
        chainId: 42161,
        abi: IHorizon,
        functionName: "stake",
        args: [quantity && parseEther(quantity)],
        enabled: parseEther(quantity).gt(0),
    });
    const { write: doStake } = useContractWrite(stakeConfig);
    const { config: claimConfig } = usePrepareContractWrite({
        address: HORIZON_ADDRESS,
        chainId: 42161,
        abi: IHorizon,
        functionName: "claimReward",
        args: [],
        enabled: !!canClaimReward,
    });
    const { write: doClaim } = useContractWrite(claimConfig);
    const { config: withdrawConfig } = usePrepareContractWrite({
        address: HORIZON_ADDRESS,
        chainId: 42161,
        abi: IHorizon,
        functionName: "exit",
        args: [],
        enabled: !!canWithdraw,
    });
    const { write: doWithdraw } = useContractWrite(withdrawConfig);

    const { config: incrementEpochConfig } = usePrepareContractWrite({
        address: TREASURY_ADDRESS,
        chainId: 42161,
        abi: TreasuryABI,
        functionName: "allocateSeigniorage",
        args: [],
        enabled: nextEpochPoint * 1000 < Date.now(),
    });
    const { write: incrementEpoch } = useContractWrite(incrementEpochConfig);

    return (
        <div className="farm-view">
            <Zoom in={true}>
                <div className="farm-card">
                    <Grid className="farm-card-grid" container direction="column" spacing={2}>
                        <Grid item>
                            <div className="farm-card-header">
                                <p className="farm-card-header-title">Horizon</p>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <div className="farm-card-metrics">
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={4}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">Next Epoch</p>
                                        <p className="farm-card-metrics-value">
                                            <Countdown daysInHours={true} date={nextEpochPoint * 1000}>
                                                <Button action={() => incrementEpoch?.()} text="Increment" />
                                            </Countdown>
                                        </p>
                                    </div>
                                </Grid>

                                <Grid item xs={4} sm={4}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">Epoch</p>
                                        <p className="farm-card-metrics-value">{epoch?.toString() || "?"}</p>
                                    </div>
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">Pending Rewards</p>
                                        <p className="farm-card-metrics-value">{parseFloat(formatEther(earned)).toFixed(4)} EMP</p>
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">APR</p>
                                        <p className="farm-card-metrics-value">{apr !== Infinity ? (apr * 100).toFixed(2) : "?"}%</p>
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">TVL</p>
                                        <p className="farm-card-metrics-value">${horizonTVL?.toFixed(2) || "0"}</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>



                    <div className="farm-card-area">
                        {!address && (
                            <div className="farm-card-wallet-notification">
                                <div className="farm-card-wallet-connect-btn" onClick={() => connect()}>
                                    <p>Connect Wallet</p>
                                </div>
                            </div>
                        )}
                        {address && (
                            <div>
                                <div className="farm-card-action-area">
                                    <div className="farm-card-action-row">
                                        <OutlinedInput
                                            type="number"
                                            placeholder="Amount"
                                            className="farm-card-action-input"
                                            value={quantity}
                                            onChange={e => setQuantity(e.target.value)}
                                            // labelWidth={0}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {/* <div onClick={setMax} className="farm-card-action-input-btn">
                                                        <p>Max</p>
                                                    </div> */}
                                                </InputAdornment>
                                            }
                                        />
                                    </div>
                                    <div className="farm-card-action-row">
                                        <Box sx={{ display: 'flex-right', color: 'white', textAlign: 'right', marginRight: '25px', width: '100%', justifyContent: 'space-evenly' }}>
                                            <p>
                                                <span
                                                    onClick={() => setQuantity(firmBalance?.formatted)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    Balance: {firmBalance?.formatted || "0"}
                                                </span>
                                            </p>
                                        </Box>
                                    </div>

                                    <div className="farm-card-action-row">
                                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
                                            {hasApproved ? <>
                                                <Box sx={{
                                                    p: 1,
                                                    m: 1,
                                                }}>
                                                    <Button isDisabled={quantity === "" || parseFloat(quantity) === 0 || firmBalance?.value.eq(0)} action={() => doStake?.()} text={"STAKE"}></Button>
                                                </Box>
                                                <Box sx={{
                                                    p: 1,
                                                    m: 1,
                                                }}>
                                                    <Button isDisabled={!canClaimReward} action={() => doClaim?.()} text={"CLAIM"}></Button>
                                                </Box>

                                                <Box sx={{
                                                    p: 1,
                                                    m: 1,
                                                }}>
                                                    <Button isDisabled={!canWithdraw} sx={{ p: 1, m: 1 }} text="Exit" action={() => doWithdraw()} />
                                                </Box>
                                            </> :
                                                <Button isDisabled={!doApprove} action={() => doApprove()} text={"Approve"} />
                                            }
                                        </Box>
                                    </div>

                                    <div className="farm-card-action-area">
                                        <div className="farm-card-metrics">
                                            <Grid container spacing={2}>
                                                <Grid item xs={4}>
                                                    <div className="farm-card-tvl">
                                                        <p className="farm-card-metrics-title">Staked Funds</p>
                                                        <p className="farm-card-metrics-value">{parseFloat(formatEther(horizonBalance))?.toFixed(4)} FIRM</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <div className="farm-card-tvl">
                                                        <p className="farm-card-metrics-title">Claim Epoch</p>
                                                        <p className="farm-card-metrics-value">
                                                            {member?.epochTimerStart.add(3).toString()}
                                                        </p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <div className="farm-card-tvl">
                                                        <p className="farm-card-metrics-title">Withdraw Epoch</p>
                                                        <p className="farm-card-metrics-value">
                                                            {member?.epochTimerStart.add(6).toString()}
                                                        </p>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}
                    </div>

                </div>

            </Zoom>
        </div>
    )
}

export default Horizon;
