import React, { useCallback, useState, useEffect } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import { useDispatch, useSelector } from "react-redux";
// import { usePathForNetwork, useWeb3Context } from "../../hooks";
// import { Grid, InputAdornment, OutlinedInput, Zoom } from "@material-ui/core";
import { useBalance } from 'wagmi'
import OutlinedInput from '@mui/material/OutlinedInput';
import Zoom from '@mui/material/Zoom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { InputAdornment } from '@mui/material';
import { useAccount, useConnect } from "wagmi";

// import Wrap from "../../components/Wrap";
// import Accordion from "../../components/Accordion";
import MemoIcon from "../../assets/tokens/MEMO.png";
import Button from "../../components/Button";
import "./farm.scss";
import IERC20ABI from "./IERC20.json";
import { useContractRead, usePrepareContractRead, usePrepareContractWrite, useContractWrite } from 'wagmi'
import { ethers } from "ethers";
import IFarmABI from "./IFarm.json";
import IRouter from "./IRouter.json";
import { parseEther } from "utils/etherUtils";

import { usePendingBalance, useFirmPrice, useEmpyrealPrice, usePool0APR, usePool1APR } from "./utils";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const FARM_ADDRESS = "0x98C35De1A27c7bdC82cE6aCADa37249934fFc08A";
const FIRM_PAIR_ADDRESS = "0x87E65159EdAfAe4bb1CCD0c94c7Ec9427409B370";
const PAIR_ADDRESS = "0x06675843400F2267060ee886C9088fF498f7c8eC";
const CAMELOT_ROUTER_ADDRESS = "0xc873fEcbd354f5A56E00E710B90EF4201db2448d";
const TREASURY_ADDRESS = "0x6460939aF9DB3C9B73D59Bdc49cC4Ac33FD230CC";

function Farm() {
    const { address } = useAccount();
    const { connect } = useConnect();
    // const dispatch = useDispatch();
    // const { provider, address, connect, chainID, checkWrongNetwork } = useWeb3Context();
    // const history = useHistory();
    // usePathForNetwork({ pathName: "farm", networkID: chainID, history });

    const [view, setView] = useState(0);
    const [reward, setPendingReward] = useState(0);
    const [selectedButton, setSelected] = useState("EMPYREAL");
    // const [wrapOpen, setWrapOpen] = useState(false);
    const [quantity, setQuantity] = useState("");
    const [pid, setPid] = useState(0);

    const pairAddress = pid === 0 ? PAIR_ADDRESS : FIRM_PAIR_ADDRESS;

    const { data: LPBalance } = useBalance({
        address,
        token: pairAddress,
    });
    const { data: tokenAllowance } = useContractRead({
        address: pairAddress,
        chainId: 42161,
        abi: IERC20ABI,
        functionName: 'allowance',
        args: [address, FARM_ADDRESS],
        enabled: address,
        watch: true,
    });
    console.log('tokenAllowance', tokenAllowance);

    const hasApproved = tokenAllowance?.gt(0);
    const firmPrice = useFirmPrice();

    const changeView = (newView) => () => {
        setView(newView);
        setQuantity("");
    };

    const { config: stakeConfig } = usePrepareContractWrite({
        address: FARM_ADDRESS,
        chainId: 42161,
        abi: IFarmABI,
        functionName: "deposit",
        args: [pid, parseEther(quantity)],
        enabled: parseFloat(quantity),
    });
    const { write: doStake } = useContractWrite(stakeConfig);

    const { config: emergencyConfig } = usePrepareContractWrite({
        address: FARM_ADDRESS,
        chainId: 42161,
        abi: IFarmABI,
        functionName: "emergencyWithdraw",
        args: [pid],
    });
    const { write: doEmergencyWithdraw } = useContractWrite(emergencyConfig);

    const pendingShares = usePendingBalance(pid, address);

    const { data: userInfo } = useContractRead({
        address: FARM_ADDRESS,
        chainId: 42161,
        abi: IFarmABI,
        functionName: 'userInfo',
        args: [pid, address],
        enabled: address,
        watch: true,
    });

    const { config: claimConfig } = usePrepareContractWrite({
        address: FARM_ADDRESS,
        chainId: 42161,
        abi: IFarmABI,
        functionName: "deposit",
        args: [pid, 0],
    });
    const { write: doClaim } = useContractWrite(claimConfig);

    const empPrice = useEmpyrealPrice();
    const { APR: pool0APR } = usePool0APR(address);
    const { APR: pool1APR } = usePool1APR(address);
    const APR = pid === 0 ? pool0APR : pool1APR;

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        if (pid === 0) {
            setPid(1);
        } else {
            setPid(0);
        }
        setValue(newValue);
    };

    return (
        <div className="farm-view">
            <Zoom in={true}>
                <div className="farm-card">
                    <Grid className="farm-card-grid" container direction="column" spacing={2}>
                        <Grid item>
                            <div className="farm-card-header">
                                <p className="farm-card-header-title">Deprecated Farms</p>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <div className="farm-card-metrics">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                sx={{
                                    '& .MuiTabs-indicator': { backgroundColor: 'white !important' },
                                    '& .MuiTab-root': { color: 'gray' },
                                    '& .Mui-selected': { color: 'white !important' },
                                }}
                            >
                                <Tab label="EMPYREAL/egARB" {...a11yProps(0)} />
                                <Tab label="FIRMAMENT/USDC" {...a11yProps(1)} />
                            </Tabs>

                        </div>
                    </Grid>

                    <Grid item>
                        <div className="farm-card-metrics">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">Total APR</p>
                                        <p className="farm-card-metrics-value">0%</p>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">Amount Staked</p>
                                        <p className="farm-card-metrics-value">{ethers.utils.formatEther(userInfo?.amount || 0)}</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">Unlock Time</p>
                                        <p className="farm-card-metrics-value">???</p>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">Pending Reward</p>
                                        <p className="farm-card-metrics-value">{ethers.utils.formatEther(pendingShares || "0")}</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <div className="farm-card-area">
                        {!address && (
                            <div className="farm-card-wallet-notification">
                                <div className="farm-card-wallet-connect-btn" onClick={connect}>
                                    <p>Connect Wallet</p>
                                </div>
                                <p className="farm-card-wallet-desc-text">Connect your wallet</p>
                            </div>
                        )}
                        {address && (
                            <div>
                                <div className="farm-card-action-area">


                                    <div className="farm-card-action-row">
                                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
                                            {/* <Box sx={{
                                                    p: 1,
                                                    m: 1,
                                                }}>
                                                    <Button action={() => doStake()} text={"STAKE"}></Button>
                                                    <Button sx={{ p: 1, m: 1 }} text="Stake" action={() => []} selected={true} />
                                                </Box> */}
                                            <Box sx={{
                                                p: 1,
                                                m: 1,
                                            }}>
                                                <Button isDisabled={false} action={() => doEmergencyWithdraw()} text={"Emergency Withdraw"}></Button>
                                                {/* <Button sx={{ p: 1, m: 1 }} text="Unstake" action={() => []} selected={true} /> */}
                                            </Box>
                                        </Box>
                                    </div>
                                    <div className="farm-card-action-help-text">
                                        <h2 style={{ marginLeft: '10px', color: 'white', textAlign: 'left' }}>Notes:</h2>
                                        <p style={{ textAlign: 'left' }}>
                                            Please withdraw your funds and migrate to the new farms.
                                        </p>

                                    </div>
                                </div>

                            </div>
                        )}
                    </div>
                </div>
            </Zoom >
        </div >
    );
}

export default Farm;
