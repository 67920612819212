import Zoom from '@mui/material/Zoom';
import Grid from '@mui/material/Grid';
import { trim } from "../../helpers/trim";

import "./dashboard.scss";
import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from "react-router-dom";
import { useNewEmpyrealPrice } from "utils/tokenPrices";
import { useRewardRate } from "utils/farmAPR";
import { useFirmMarketCap, useHorizonAPR } from "utils/tvl";

function Dashboard() {
    const navigate = useNavigate();

    const { empyrealPriceInUSD } = useNewEmpyrealPrice();

    const isAppLoading = false;

    return (
        <div className="dashboard-view">
            <div className="dashboard-infos-wrap">
                <Zoom in={true}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <h1 style={{ textAlign: 'center', color: 'white' }}>
                                EMPYREAL
                            </h1>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <div className="dashboard-card">
                                <p className="card-title">EMP Price</p>
                                <p className="card-value">{isAppLoading ? <Skeleton width="160px" /> : `$${trim(empyrealPriceInUSD, 2)}`}</p>
                            </div>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <div className="dashboard-card">
                                <p className="card-title">Market Cap</p>
                                <p className="card-value">{isAppLoading ? <Skeleton width="160px" /> : `$${trim(empyrealPriceInUSD * 300000, 2)}`}</p>
                            </div>
                        </Grid>
                        {/* 
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <div className="dashboard-card">
                                <p className="card-title">Market Cap</p>
                                <p className="card-value">
                                    {isAppLoading ? (
                                        <Skeleton width="160px" />
                                    ) : (
                                        new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                            maximumFractionDigits: 0,
                                            minimumFractionDigits: 0,
                                        }).format(empyrealMarketCap)
                                    )}
                                </p>
                            </div>
                        </Grid> */}

                        {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                            <div className="dashboard-card">
                                <p className="card-title">Firmament Price</p>
                                <p className="card-value">
                                    {isAppLoading ? (
                                        <Skeleton width="160px" />
                                    ) : (
                                        new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 0,
                                        }).format(firmamentPrice)
                                    )}
                                </p>
                            </div>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <div className="dashboard-card">
                                <p className="card-title">TVL</p>
                                <p className="card-value">
                                    {isAppLoading ? (
                                        <Skeleton width="160px" />
                                    ) : (
                                        new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 0,
                                        }).format(tvl)
                                    )}
                                </p>
                            </div>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <div className="dashboard-card">
                                <p className="card-title">EMP/egARB APR</p>
                                <p className="card-value">
                                    {isAppLoading ? (
                                        <Skeleton width="160px" />
                                    ) : (
                                        (pool0APR * 100).toFixed(2)
                                    )}%
                                </p>
                            </div>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <div className="dashboard-card">
                                <p className="card-title">FIRM/USDC APR</p>
                                <p className="card-value">
                                    {isAppLoading ? (
                                        <Skeleton width="160px" />
                                    ) : (
                                        (pool1APR * 100).toFixed(2)
                                    )}%
                                </p>
                            </div>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <div className="dashboard-card">
                                <p className="card-title">FIRM Market Cap</p>
                                <p className="card-value">
                                    ${isAppLoading ? (
                                        <Skeleton width="160px" />
                                    ) : (
                                        (firmMarketCap).toLocaleString(undefined, { maximumFractionDigits: 2 })
                                    )}
                                </p>
                            </div>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <div className="dashboard-card">
                                <p className="card-title">Horizon APR</p>
                                <p className="card-value">
                                    {isAppLoading ? (
                                        <Skeleton width="160px" />
                                    ) : (
                                        (horizonAPR * 100)?.toFixed(2)
                                    )}%
                                </p>
                            </div>
                        </Grid> */}
                        {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                            <div className="dashboard-card">
                                <p className="card-title">TVL</p>
                                <p className="card-value">
                                    {isAppLoading ? (
                                        <Skeleton width="250px" />
                                    ) : (
                                        new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                            maximumFractionDigits: 0,
                                            minimumFractionDigits: 0,
                                        }).format(stakingTVL)
                                    )}
                                </p>
                            </div>
                        </Grid> */}

                        {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                            <div className="dashboard-card">
                                <p className="card-title">Treasury Balance</p>
                                <p className="card-value">
                                    {isAppLoading ? (
                                        <Skeleton width="250px" />
                                    ) : (
                                        new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                            maximumFractionDigits: 0,
                                            minimumFractionDigits: 0,
                                        }).format(treasuryBalance)
                                    )}
                                </p>
                            </div>
                        </Grid>


                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <div className="dashboard-card">
                                <p className="card-title">Current Sell Tax</p>
                                <p className="card-value">
                                    {isAppLoading ? (
                                        <Skeleton width="250px" />
                                    ) : (
                                        "2.4%"
                                    )}
                                </p>
                            </div>
                        </Grid> */}
                    </Grid>
                </Zoom>
            </div>
        </div>
    );
}

export default Dashboard;
