import React, { useCallback, useState, useEffect } from "react";
import Countdown from 'react-countdown';
import Zoom from '@mui/material/Zoom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import { InputAdornment } from '@mui/material';
import { InjectedConnector } from 'wagmi/connectors/injected'
import { useAccount, useConnect } from "wagmi";
import Button from "../../components/Button";
import { useContractRead, usePrepareContractWrite, useContractWrite } from 'wagmi'
import { ethers } from "ethers";
import { useBalance } from 'wagmi'

import { useHorizonTVL, useHorizonAPR } from "utils/tvl";
import { formatEther, parseEther } from "utils/etherUtils";
import { IERC20, TreasuryABI, IArbitrumStaking, IManager } from "utils/abis";
import { ARBITRUM_ADDRESS } from "constants";

import "./horizon.scss";
import { TREASURY_ADDRESS } from "constants";
import { useArbitrumPrice } from "utils/tokenPrices";

const ARBITRUM_STAKING = "0x2BcD1d383d1b01d6705626666EdF9608d255a490";
const MANAGER_ADDRESS = "0x9BFcd025d7eec3eE1C2AB8AA12F22F45c0dad900"

function Elysium() {
    const { address } = useAccount();
    const { connect } = useConnect({
        connector: new InjectedConnector(),
    })
    const [quantity, setQuantity] = useState("");
    const { data: stakedArbitrumBalance } = useBalance({
        address: ARBITRUM_STAKING,
        token: ARBITRUM_ADDRESS,
        watch: true,
    });
    const arbPrice = useArbitrumPrice();
    const tvl = stakedArbitrumBalance?.formatted * arbPrice;
    console.log('stakedArbitrumBalance', stakedArbitrumBalance);

    const { data: tokenAllowance } = useContractRead({
        address: ARBITRUM_ADDRESS,
        chainId: 42161,
        abi: IERC20,
        functionName: 'allowance',
        args: [address, ARBITRUM_STAKING],
        enabled: address,
        watch: true,
    });
    console.log('tokenAllowance', tokenAllowance);
    const { data: member } = useContractRead({
        address: ARBITRUM_STAKING,
        chainId: 42161,
        abi: IArbitrumStaking,
        functionName: 'members',
        args: [address],
        enabled: !!address,
    });
    console.log('member', member);
    const { data: multiple } = useContractRead({
        address: ARBITRUM_STAKING,
        chainId: 42161,
        abi: IArbitrumStaking,
        functionName: 'getMultiple',
        args: [address],
        enabled: !!address,
    });
    const { data: epoch } = useContractRead({
        address: MANAGER_ADDRESS,
        chainId: 42161,
        abi: IManager,
        functionName: 'epoch',
        args: [],
    });
    const { data: nextEpochPoint } = useContractRead({
        address: MANAGER_ADDRESS,
        chainId: 42161,
        abi: IManager,
        functionName: 'nextEpochPoint',
        args: [],
    });
    console.log('nextEpochPoint', nextEpochPoint);

    const { data: arbStakingBalance } = useContractRead({
        address: ARBITRUM_STAKING,
        chainId: 42161,
        abi: IArbitrumStaking,
        functionName: 'balanceOf',
        args: [address],
        enabled: !!address,
        watch: true,
    });
    const { data: earned } = useContractRead({
        address: ARBITRUM_STAKING,
        chainId: 42161,
        abi: IArbitrumStaking,
        functionName: 'earned',
        args: [address],
        enabled: !!address && member?.rewardEarned?.gt(0),
        watch: true,
    });
    console.log('earned', earned);

    const { data: arbitrumBalance } = useBalance({
        address,
        token: ARBITRUM_ADDRESS,
        watch: true,
    });
    const { config: approveConfig } = usePrepareContractWrite({
        address: ARBITRUM_ADDRESS,
        chainId: 42161,
        abi: IERC20,
        functionName: "approve",
        args: [ARBITRUM_STAKING, ethers.constants.MaxUint256],
    });
    const { write: doApprove } = useContractWrite(approveConfig);
    const hasApproved = (tokenAllowance?.gt(0) && tokenAllowance?.gte(parseEther(quantity))) || arbitrumBalance?.value.eq(0);

    const { config: stakeConfig } = usePrepareContractWrite({
        address: ARBITRUM_STAKING,
        chainId: 42161,
        abi: IArbitrumStaking,
        functionName: "stake",
        args: [parseEther(quantity)],
        enabled: parseEther(quantity).gt(0),
    });
    const { write: doStake } = useContractWrite(stakeConfig);
    const { config: claimConfig } = usePrepareContractWrite({
        address: ARBITRUM_STAKING,
        chainId: 42161,
        abi: IArbitrumStaking,
        functionName: "claimReward",
        args: [],
        enabled: parseEther(quantity)?.lte(arbitrumBalance?.value || "0"),
    });
    const { write: doClaim } = useContractWrite(claimConfig);
    const { config: withdrawConfig } = usePrepareContractWrite({
        address: ARBITRUM_STAKING,
        chainId: 42161,
        abi: IArbitrumStaking,
        functionName: "exit",
        args: [],
        enabled: true,
        // enabled: arbStakingBalance?.gt(0)
    });
    const { write: doWithdraw } = useContractWrite(withdrawConfig);
    console.log('doWithdraw', doWithdraw);

    const { config: incrementEpochConfig } = usePrepareContractWrite({
        address: MANAGER_ADDRESS,
        chainId: 42161,
        abi: IManager,
        functionName: "allocateGovernanceIncentive",
        args: [],
        enabled: nextEpochPoint * 1000 < Date.now(),
    });
    const { write: incrementEpoch } = useContractWrite(incrementEpochConfig);

    return (
        <div className="farm-view">
            <Zoom in={true}>
                <div className="farm-card">
                    <Grid className="farm-card-grid" container direction="column" spacing={2}>
                        <Grid item>
                            <div className="farm-card-header">
                                <p className="farm-card-header-title">Arbitrum Single Staking</p>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <div className="farm-card-metrics">
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={4}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">Next Epoch</p>
                                        <p className="farm-card-metrics-value">
                                            <Countdown daysInHours={true} date={nextEpochPoint * 1000}>
                                                <Button action={() => incrementEpoch?.()} text="Increment" />
                                            </Countdown>
                                        </p>
                                    </div>
                                </Grid>

                                <Grid item xs={4} sm={4}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">Epoch</p>
                                        <p className="farm-card-metrics-value">{epoch?.toString() || "?"}</p>
                                    </div>
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">Pending Rewards</p>
                                        <p className="farm-card-metrics-value">{parseFloat(formatEther(earned)).toFixed(8)} FIRM</p>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">APR</p>
                                        <p className="farm-card-metrics-value">{(5 * Number(multiple?.div(10000)._hex)).toFixed(2)}%</p>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">Multiplier</p>
                                        <p className="farm-card-metrics-value">{Number(multiple?.div(10000)._hex)?.toFixed(2)}x</p>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="farm-card-tvl">
                                        <p className="farm-card-metrics-title">TVL</p>
                                        <p className="farm-card-metrics-value">${tvl?.toFixed(2) || "0"}</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>



                    <div className="farm-card-area">
                        {!address && (
                            <div className="farm-card-wallet-notification">
                                <div className="farm-card-wallet-connect-btn" onClick={() => connect()}>
                                    <p>Connect Wallet</p>
                                </div>
                            </div>
                        )}
                        {address && (
                            <div>
                                <div className="farm-card-action-area">
                                    <div className="farm-card-action-row">
                                        <OutlinedInput
                                            type="number"
                                            placeholder="Amount"
                                            className="farm-card-action-input"
                                            value={quantity}
                                            onChange={e => setQuantity(e.target.value)}
                                            // labelWidth={0}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {/* <div onClick={setMax} className="farm-card-action-input-btn">
                                                        <p>Max</p>
                                                    </div> */}
                                                </InputAdornment>
                                            }
                                        />
                                    </div>
                                    <div className="farm-card-action-row">
                                        <Box sx={{ display: 'flex-right', color: 'white', textAlign: 'right', marginRight: '25px', width: '100%', justifyContent: 'space-evenly' }}>
                                            <p>
                                                <span
                                                    onClick={() => setQuantity(arbitrumBalance?.formatted)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    Balance: {arbitrumBalance?.formatted || "0"}
                                                </span>
                                            </p>
                                        </Box>
                                    </div>

                                    <div className="farm-card-action-row">
                                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
                                            {hasApproved ? <>
                                                <Box sx={{
                                                    p: 1,
                                                    m: 1,
                                                }}>
                                                    <Button isDisabled={quantity === "" || parseFloat(quantity) === 0 || arbitrumBalance?.value.eq(0)} action={() => doStake?.()} text={"STAKE"}></Button>
                                                </Box>
                                                <Box sx={{
                                                    p: 1,
                                                    m: 1,
                                                }}>
                                                    <Button action={() => doClaim?.()} text={"CLAIM"}></Button>
                                                </Box>

                                                <Box sx={{
                                                    p: 1,
                                                    m: 1,
                                                }}>
                                                    <Button sx={{ p: 1, m: 1 }} text="Exit" action={() => doWithdraw()} />
                                                </Box>
                                            </> :
                                                <Button isDisabled={!doApprove} action={() => doApprove()} text={"Approve"} />
                                            }
                                        </Box>
                                    </div>

                                    <div className="farm-card-action-area">
                                        <div className="farm-card-metrics">
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <div className="farm-card-tvl">
                                                        <p className="farm-card-metrics-title">Staked Funds</p>
                                                        <p className="farm-card-metrics-value">{parseFloat(formatEther(arbStakingBalance))?.toFixed(4)} ARB</p>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}
                    </div>

                </div>

            </Zoom>
        </div>
    )
}

export default Elysium;
